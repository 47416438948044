<template>
<button class="btn btn-outline-secondary mb-2" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

<h1 clss="mb-0">Unternehmens- und Stakeholderthemen</h1>
<small>{{ unternehmen.firmenname }} - {{ umfrage.titel }}</small>

<hr class="my-4">

<LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

<div v-else>
  <div class="row">
    <div class="col-5">
      <h4>Themen</h4>
      <SelectUnternehmensthemaRow v-for="thema in filteredUnternehmensthemen" :key="thema.id" :thema="thema" :umfrageGuid="umfrageguid" :unternehmenguid="unternehmenguid" @add-thema-longlist="addThemaLonglist" />
    
      <hr class="my-4">

      <router-link :to="{name: 'EditUnternehmensthema', query: {unternehmenguid: unternehmenguid}}" class="btn btn-secondary btn-sm"><font-awesome-icon icon="plus" /> Eigenes Thema hinzufügen</router-link>

    </div>

    <div class="col offset-1">
      <h4>Ausgewählte Themen</h4>
      <div v-for="(item, index) in selectedUnternehmensthemen" :key="index" class="d-flex justify-content-between align-items-center">
        <div class="row py-2 mb-2 mx-1 bg-white shadow-sm rounded flex-fill">
          <div class="col my-auto">{{ getThemaById(item.themaid).kategorie }}</div>
          <div class="col my-auto">{{ getThemaById(item.themaid).thema }}</div>
        </div>
        <button type="button" class="btn" @click="removeThemaLongList(item)"><font-awesome-icon icon="trash" /></button>
      </div>
    </div>
  </div>

  <div v-if="selectedUnternehmensthemen.length > 0 || deleteUnternehmensthemenList.length > 0" class="d-flex justify-content-end mt-3 pt-2 border-top">
    <button class="btn btn-outline-secondary me-2" @click="cancel()">Abbrechen</button>
    <button class="btn btn-primary" @click="saveLongList()">Speichern</button>
  </div>
</div>


</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { ActionTypes as UnternehmensthemaActionTypes } from '@/store/modules/Unternehmensthema/actions';
import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import SelectUnternehmensthemaRow from '@/components/Umfrage/SelectUnternehmensthemaRow.vue';

import { Unternehmen } from '@/models/UnternehmenModels';
import { Umfrage, LongList } from '@/models/UmfrageModels';
import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

export default defineComponent({
  name: 'SelectUnternehmensthemen',

  components: {
    LoadingSpinner,
    SelectUnternehmensthemaRow
  },

  props: {
    unternehmenguid: {
      type: String as PropType<string>,
      required: true
    },

    umfrageguid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter();

    const loading = computed(() => store.getters.status.getUnternehmensthemaLoading || store.getters.status.createLonglistLoading || store.getters.status.deleteLonglistLoading);
    const errorMsg = computed(() => store.getters.status.unternehmensthemaErrorMsg);

    const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid))
    const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid))

    const unternehmensthemen = computed(() => store.getters.unternehmensthemen)

    const filteredUnternehmensthemen = computed(() => {
      return unternehmensthemen.value.filter((ut: Unternehmensthema) => {
        return !selectedUnternehmensthemen.value.find((s) => s.themaid == ut.id)
      })
    })

    function getUnternehmensthemen() {
      store.dispatch(UnternehmensthemaActionTypes.GetUnternehmensthema, props.unternehmenguid);
    }

    function getThemaById(id: number | null): Unternehmensthema {
      let response = new Unternehmensthema();
      response.kategorie = "unbekannt";
      response.thema = "unbekannt";

      if (id != null) {
        response = unternehmensthemen.value.find((ut: Unternehmensthema) => ut.id == id)
      }

      return response;
    }

    const selectedUnternehmensthemen = ref(new Array<LongList>());
    if (umfrage.value != null && umfrage.value.longlist.length > 0) {
      for (var item of umfrage.value.longlist.filter((l: LongList) => l.frageid == null)) {
        if (selectedUnternehmensthemen.value.find((s) => s.themaid == item.themaid) == null) {
          addThemaLonglist(item);
        }
        // selectedUnternehmensthemen.value.push(item);
      }
    }

    const deleteUnternehmensthemenList = ref(new Array<LongList>());

    function addThemaLonglist(item: LongList) {
      selectedUnternehmensthemen.value.push(item);
      sessionStorage.setItem("selectedUnternehmensthemen", JSON.stringify(selectedUnternehmensthemen.value));
    }
    
    function removeThemaLongList(item: LongList) {
      if (item.id != 0) {
        deleteUnternehmensthemenList.value.push(item);
      }

      const index = selectedUnternehmensthemen.value.indexOf(item);
      selectedUnternehmensthemen.value.splice(index, 1);
      sessionStorage.setItem("selectedUnternehmensthemen", JSON.stringify(selectedUnternehmensthemen.value));
    }

    function saveLongList() {
      for (var item of deleteUnternehmensthemenList.value) {
        store.dispatch(UmfrageActionTypes.DeleteLonglist, item)
      }

      const requestData = selectedUnternehmensthemen.value.filter((l: LongList) => l.id == 0);
      if (requestData.length > 0) {
        store.dispatch(UmfrageActionTypes.CreateLonglistRange, requestData)
        .then(() => {
          if (errorMsg.value == "") {
            cancel()
          }
        })
      }
      else {
        cancel()
      }
    }
    
    function cancel() {
      selectedUnternehmensthemen.value = new Array<LongList>();
      deleteUnternehmensthemenList.value = new Array<LongList>();
      sessionStorage.removeItem("selectedUnternehmensthemen");
      router.push({name: "UnternehmenUmfrage", params: {unternehmenguid: props.unternehmenguid, umfrageguid: props.umfrageguid}})
    }

    return {
      router,

      loading,
      errorMsg,

      unternehmen,
      umfrage,

      filteredUnternehmensthemen,
      unternehmensthemen,
      getUnternehmensthemen,
      getThemaById,

      selectedUnternehmensthemen,
      addThemaLonglist,
      removeThemaLongList,
      deleteUnternehmensthemenList,

      saveLongList,
      cancel,
    }
  },

  mounted() {
    this.getUnternehmensthemen()

    this.selectedUnternehmensthemen = sessionStorage.getItem("selectedUnternehmensthemen") != null ? JSON.parse(sessionStorage.selectedUnternehmensthemen) : new Array<LongList>();
  }
})
</script>