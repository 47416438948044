<template>
  <div v-if="user != undefined && (user.benutzerrolleId == 1 || user.benutzerrolleId == 2)" class="d-flex justify-content-between align-items-center">
    <router-link  :to="{name: 'Unternehmen'}" class="btn btn-outline-secondary mb-2"><font-awesome-icon icon="chevron-left" /> Zurück</router-link>

    <!-- <router-link v-if="unternehmen != null && user.benutzerrolleId == 1" :to="{name: 'EditUnternehmen', query: {unternehmenguid: guid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>
    <router-link v-if="unternehmen != null  && user.benutzerrolleId == 2" :to="{name: 'UnternehmenSettings', query: {unternehmenguid: guid}}" class="btn"><font-awesome-icon icon="gear" /></router-link> -->
    <router-link v-if="unternehmen != null" :to="{name: 'UnternehmenSettings', params: {guid: guid}}" class="btn"><font-awesome-icon icon="gear" /></router-link>
  </div>

  <LoadingSpinner v-if="getUnternehmenLoading" text="... Daten werden geladen ..." />
  <div v-else-if="unternehmen == null" class="alert alert-danger">
    Das Unternehmen konnte nicht gefunden werden.
  </div>

  <div v-else>

    <div class="bg-white shadow-sm p-3">
      <h1 class="border-bottom">{{ unternehmen?.firmenname }}</h1>
      <p><strong>Firmennummer:</strong> {{ unternehmen?.firmennummer }}</p> 

      <div class="row">
        <div class="col">
          <p><strong>Strase:</strong> {{ unternehmen?.strasse }}</p> 
          <p><strong>Plz/Ort:</strong> {{ unternehmen?.plz }} {{ unternehmen?.ort }}</p> 
          <p><strong>Land:</strong> {{ unternehmen?.land }}</p> 
        </div>

        <div class="col">
          <p><strong>Ansprechpartner:</strong> {{ unternehmen?.ansprechpartner }}</p> 
          <p><strong>Ansprechpartner E-Mail:</strong> {{ unternehmen?.ansprechpartnerEmail }}</p> 
          <p><strong>Ansprechpartner Telefon:</strong> {{ unternehmen?.ansprechpartnerTel }}</p> 
        </div>
      </div>
    </div>

    <div>

      <hr class="my-4">

      
      <div class="d-flex justify-content-between">
        <h2>Umfragen</h2>

        <button class="btn" @click="loadUmfragen()"><font-awesome-icon icon="rotate" /></button>
      </div>

      <div class="d-flex">
        <button class="btn btn-secondary btn-sm" @click="showModal()"><font-awesome-icon icon="plus" /> Hinzufügen</button>
      </div>

      <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />
      
      <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
        Es ist ein Fehler beim Laden der Unternehmen aufgetreten. Bitte probieren Sie es später erneut.
      </div>

      <div v-if="umfragen.length > 0" class="mt-5">
        <div class="row mx-1 mb-2">
          <div class="col-3"><strong>Umfrage</strong></div>
          <div class="col-2"><strong>Start</strong></div>
          <div class="col-2"><strong>Ende</strong></div>
          <div class="col-3"><strong>Anzahl Teilnehmer</strong></div>
          <div class="col-2"></div>
        </div>
        <div v-for="umfrage in umfragen" :key="umfrage.guid">
          <UmfrageRow :umfrage="umfrage" />
        </div>
      </div>

      <div v-else-if="!loading && errorMsg == '' && umfragen.length == 0" class="alert alert-info mt-5">
        Es wurden keine Unternehmen gefunden.
      </div>


    <!-- Unternehmen hinzufügen Modal -->
    <div
      class="modal fade"
      id="addUmfrageModal"
      tabindex="-1"
      aria-labelledby="addUmfrageModal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      ref="addUmfrageModalRef"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addUmfrageModalLabel">
              Neue Umfrage erstellen
            </h5>
            <button
              type="button"
              class="btn-close"
              aria-label="Close"
              @click="closeModal()"
            ></button>
          </div>

          <div class="modal-body">
            <LoadingSpinner v-if="createLoading" />

            <div v-else-if="!createLoading && errorMsg != ''" class="alert alert-warning">
              {{ errorMsg }}
            </div>

            <form v-else @submit.prevent="createNeueUmfrage()">
              <div class="mb-3">
                <label for="titel" class="form-label">Titel</label>
                <input type="text" class="form-control" id="titel" v-model="neueUmfrage.titel" required>
              </div>

              <div class="row mb-3">
                <div class="col">
                  <label for="startdatum" class="form-label">Startdatum</label>
                  <input type="date" class="form-control" id="startdatum" v-model="startDatumConvert" required>
                </div>

                <div class="col">
                  <label for="enddatum" class="form-label">Enddatum</label>
                  <input type="date" class="form-control" id="enddatum" v-model="endDatumConvert" required>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-secondary me-3"
                  @click="closeModal()"
                  >
                  Abbrechen
                </button>
                
                <button
                  type="submit"
                  class="btn btn-primary"
                  >
                  Speichern
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    </div>
  </div>

</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap';

import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';
import { ActionTypes as UnternehmenActionTypes } from '@/store/modules/Unternehmen/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import UmfrageRow from "@/components/Umfrage/UmfrageRow.vue";

import { Umfrage } from '@/models/UmfrageModels';
import { Unternehmen } from '@/models/UnternehmenModels';

export default defineComponent({
  name: "DetailsUnternehmenView",

  components: {
    LoadingSpinner,
    UmfrageRow
  },

  props: {
    guid: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter();

    const user = computed(() => store.getters.authUser?.benutzer)
    
    const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.guid))
    const umfragen = computed(() => store.getters.umfragen.filter((u: Umfrage) => u.unternehmenGuid == props.guid))

    const loading = computed(() => store.getters.status.getUmfrageLoading)
    const errorMsg = computed(() => store.getters.status.umfrageErrorMsg)

    const getUnternehmenLoading = computed(() => store.getters.status.getUnternehmenLoading)
    const unternehmenErrorMsg = computed(() => store.getters.status.unternehmenErrorMsg)

    function loadUmfragen() {
      store.dispatch(UmfrageActionTypes.GetUmfrageByUnternehmenguid, props.guid)
      .then(() => {
        if (unternehmenErrorMsg.value == "") {
          router.push({name: "DetailsUnternehmen", params: {guid: props.guid}})
        }
      })
    }

    const createLoading = computed(() => store.getters.status.createUmfrageLoading)
    const neueUmfrage = ref(new Umfrage())

    const startDatumConvert = computed({
      get () {
        if (neueUmfrage.value.startdatum == null) {
          const date = new Date();
          return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);

        }
        else {
          return neueUmfrage.value.startdatum.getFullYear() + "-" + ("0" + (neueUmfrage.value.startdatum.getMonth() + 1)).slice(-2) + "-" + ("0" + neueUmfrage.value.startdatum.getDate()).slice(-2);
        }
      },

      set (value) {
        neueUmfrage.value.startdatum = new Date(value);
      }
    })

    const endDatumConvert = computed({
      get () {
        if (neueUmfrage.value.enddatum == null) {
          const date = new Date();
          return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);

        }
        else {
          return neueUmfrage.value.enddatum.getFullYear() + "-" + ("0" + (neueUmfrage.value.enddatum.getMonth() + 1)).slice(-2) + "-" + ("0" + neueUmfrage.value.enddatum.getDate()).slice(-2);
        }
      },

      set (value) {
        neueUmfrage.value.enddatum = new Date(value);
      }
    })

    const addUmfrageModalRef = ref();
    function closeModal() {
      Modal.getInstance(addUmfrageModalRef.value)?.hide();
    }

    function showModal() {
      if (addUmfrageModalRef.value) {
        neueUmfrage.value = new Umfrage()
        new Modal(addUmfrageModalRef.value).show();
      }
    }

    function createNeueUmfrage() {
      neueUmfrage.value.unternehmenGuid = props.guid
      store.dispatch(UmfrageActionTypes.CreateUmfrage, neueUmfrage.value)
        .then(() => {
          if (errorMsg.value == "") {
            closeModal()
          }
        })
    }

    function getUnternehmenByGuid() {
      store.dispatch(UnternehmenActionTypes.GetUnternehmenByGuid, props.guid)
      .then(() => {
        loadUmfragen();
      })
    }

    return {
      router,
      user,

      unternehmen,
      umfragen,
      loading,
      errorMsg,
      loadUmfragen,
      createLoading,
      
      neueUmfrage,
      startDatumConvert,
      endDatumConvert,


      addUmfrageModalRef,
      closeModal,
      showModal,
      createNeueUmfrage,

      getUnternehmenLoading,
      getUnternehmenByGuid
    }
  },

  mounted () {
    if (this.unternehmen == null) {
      this.getUnternehmenByGuid();
    }
    else {
      this.loadUmfragen();
    }
  }
})
</script>