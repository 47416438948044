import { ActionContext, ActionTree } from "vuex";
import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { ApiService } from "@/services/ApiService";

import { RootState } from '@/store';
import { State } from "./state";
import router from "@/router"

import { Mutations, MutationTypes } from "./mutations";
import { AuthUser, LoginRequest, LogoutRequest, RefreshLoginRequest, RegisterRequest } from '@/models/AuthModels';
import { ErrorResponseData } from "@/models/CommonModels";

export enum ActionTypes {
  Login = "LOGIN",
  Refresh = "REFRESH",
  Logout = "LOGOUT",
  Register = "REGISTER"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.Login](context: ActionArguments, data: LoginRequest): Promise<void>
  [ActionTypes.Refresh](context: ActionArguments): Promise<void>
  [ActionTypes.Logout](context: ActionArguments): Promise<void>
  [ActionTypes.Register](context: ActionArguments, data: RegisterRequest): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.Login]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.authLoginLoading = true;
    status.authErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.login(data)
      .then(res => {
        const data: AuthUser = res.data
        commit(MutationTypes.SetAuthUser, data)
      })
      .catch(error => {
        if (error.response.status == 401) {
          status.authErrorMsg = error.response.data.title;
        }
        else {
          status.authErrorMsg = error.response;
        }
      })
      .finally(() => {
        status.authLoginLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.Refresh]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.fragenErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    const data = new RefreshLoginRequest(rootGetters.authUser.refreshtoken)

    return ApiService.refreshLogin(data)
      .then(res => {
        const data: AuthUser = res.data
        commit(MutationTypes.SetAuthUser, data)
      })
      .catch(error => {
        status.authErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.Logout]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.authLogoutLoading = true;
    status.authErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    const data = new LogoutRequest(rootGetters.authUser.refreshtoken)

    return ApiService.logout(data)
      .then(() => {
      })
      .finally(() => {
        status.authLogoutLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
        commit(MutationTypes.ClearAuthUser, undefined);
        sessionStorage.clear();
        localStorage.clear();
      })
  },

  async [ActionTypes.Register]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.authRegisterLoading = true;
    status.authErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.register(data)
      .then(() => {
      })
      .catch(error => {
        status.authErrorMsg = error.response;
      })
      .finally(() => {
        status.authRegisterLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}