<template>
  <div class="d-flex justify-content-between">
    <h1>Benutzer</h1>

    <button class="btn" @click="loadBenutzer()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <div class="d-flex">
    <button class="btn btn-secondary btn-sm" @click="showModal()"><font-awesome-icon icon="plus" /> Hinzufügen</button>
  </div>

  <LoadingSpinner v-if="loading" text="... Benutzer werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
  </div>

  <div v-if="benutzer.length > 0" class="mt-5">
    <div class="row mx-1 mb-2">
      <div class="col-4"><strong>Name</strong></div>
      <div class="col-3"><strong>E-Mail</strong></div>
      <div class="col"></div>
    </div>
    <div v-for="user in benutzer" :key="user.id">
      <BenutzerRow :benutzer="user" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && benutzer.length == 0" class="alert alert-info mt-5">
    Es wurden keine Benuzter gefunden.
  </div>

  <!-- Benutzer hinzufügen Modal -->
  <div
    class="modal fade"
    id="addBenutzerModal"
    tabindex="-1"
    aria-labelledby="addBenutzerModal"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="addBenutzerModalRef"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addBenutzerModalLabel">
            Benutzer hinzufügen
          </h5>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="closeModal()"
          ></button>
        </div>

        <div class="modal-body">
          <LoadingSpinner v-if="createLoading" />


          <form v-else @submit.prevent="createNeuenBenutzer()">
            <div class="mb-3">
              <label for="vorname" class="form-label">Vorname</label>
              <input type="text" class="form-control" id="vorname" v-model="neuerBenutzer.vorname" required>
            </div>

            <div class="mb-3">
              <label for="name" class="form-label">Name</label>
              <input type="text" class="form-control" id="name" v-model="neuerBenutzer.name" required>
            </div>

            <div class="mb-3">
              <label for="email" class="form-label">E-Mail</label>
              <input type="email" class="form-control" id="email" v-model="neuerBenutzer.email" autocomplete="false" required>
            </div>

            <div class="mb-3">
              <label for="passwort" class="form-label">Passwort</label>
              <input type="password" class="form-control" id="passwort" v-model="neuerBenutzer.passwort" autocomplete="false" required>
            </div>

            <div v-if="user != null && user.benutzerrolleId == 1" class="mb-3">
              <label for="rolle" class="form-label">Rolle</label>
              <select class="form-select" id="rolle" v-model="neuerBenutzer.benutzerrolleId">
                <option v-for="rolle in benutzerrollen" :key="rolle.id" :value="rolle.id">{{rolle.titel}}</option>
              </select>
            </div>


            
            <div v-if="!createLoading && errorMsg != ''" class="alert alert-danger">
              {{ errorMsg }}
            </div>

            <div class="d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-secondary me-3"
                @click="closeModal()"
                >
                Abbrechen
              </button>
              
              <button
                type="submit"
                class="btn btn-primary"
                >
                Speichern
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex'
import { ActionTypes as BenutzerActionTypes } from "@/store/modules/Benutzer/actions"
import { Modal } from 'bootstrap';

import LoadingSpinner from "@/components/LoadingSpinner.vue"
import BenutzerRow from "@/components/Benutzer/BenutzerRow.vue"

import { Benutzer, CreateBenutzerRequest, BenutzerUnternehmen, BeraterUnternehmenRequest } from '@/models/BenutzerModels';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "Unternehmen",

  components: {
    LoadingSpinner,
    BenutzerRow
  },

  setup() {
    const store = useStore()
    const route = useRoute()

    const loading = computed(() => store.getters.status.getBenutzerLoading || store.getters.status.getBenutzerrollenLoading)
    const createLoading = computed(() => store.getters.status.createBenutzerLoading || store.getters.status.createBenutzerUnternehmenLoading)

    const errorMsg = computed(() => store.getters.status.benutzerErrorMsg)

    const user = computed(() => store.getters.authUser?.benutzer)

    const benutzerrollen = computed(() => store.getters.benutzerrollen)
    const benutzer = computed(() => {
      if (route.params.guid != undefined) {
        return store.getters.benutzer.filter((b: Benutzer) => b.benutzerrolleId == 3)
      }
      else {
        return store.getters.benutzer
      }
    })
    
    function loadBenutzer() {
      if (route.params.guid != undefined) {
        const guid = typeof route.params.guid == "string" ? route.params.guid : route.params.guid[0]
        store.dispatch(BenutzerActionTypes.GetBenutzer, guid)
      }
      else if (user.value != null && user.value.benutzerrolleId == 1) {
        store.dispatch(BenutzerActionTypes.GetBenutzer, undefined)
      }
    }
    
    const neuerBenutzer = ref(new CreateBenutzerRequest())

    const addBenutzerModalRef = ref();
    function closeModal() {
      Modal.getInstance(addBenutzerModalRef.value)?.hide();
    }

    function showModal() {
      if (addBenutzerModalRef.value) {
        neuerBenutzer.value = new CreateBenutzerRequest()
        new Modal(addBenutzerModalRef.value).show();
      }
    }

    async function createNeuenBenutzer() {
      await store.dispatch(BenutzerActionTypes.CreateBenutzer, neuerBenutzer.value)
      if (errorMsg.value == "") {
        if (route.params.guid != undefined) {
          const guid = typeof route.params.guid == "string" ? route.params.guid : route.params.guid[0]
          const newUser = store.getters.benutzer.find((b: Benutzer) => b.email == neuerBenutzer.value.email)
          if (newUser != undefined) {
            await store.dispatch(BenutzerActionTypes.AddBenutzerUnternehmen, new BenutzerUnternehmen(newUser.id, guid))
            loadBenutzer()
          }
        }

        closeModal()
      }
    }

    return {
      route,

      loading,
      errorMsg,

      user,

      benutzerrollen,
      benutzer,
      
      loadBenutzer,
      addBenutzerModalRef,
      closeModal,
      showModal,
      neuerBenutzer,
      createNeuenBenutzer,
      createLoading
    }
  },

  mounted () {
    this.loadBenutzer()

    console.log("###################");
    console.log(this.route);
    console.log("###################");
  }
})
</script>