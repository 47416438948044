import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-7a4d8bb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row py-2 mb-2 mx-1 bg-white shadow-sm umfrage-row rounded" }
const _hoisted_2 = { class: "col-1 my-auto" }
const _hoisted_3 = { class: "col-1 my-auto" }
const _hoisted_4 = { class: "col-1 my-auto" }
const _hoisted_5 = { class: "col-4 my-auto" }
const _hoisted_6 = { class: "col-4 my-auto" }
const _hoisted_7 = { class: "col-1 my-auto text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.fragenkatalog.nr), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fragenkatalog.quelle), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.fragenkatalog.aspektDE), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.fragenkatalog.themaDE), 1),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.fragenkatalog.frageDE), 1),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_link, {
        to: {name: 'DetailsFragenkatalog', params: { fragenkatalogid: _ctx.fragenkatalog.id.toString()}},
        class: "btn btn-primary btn-sm"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass-plus" })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}