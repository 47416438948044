<template>
  <div v-if="unternehmensthemaid != '' && unternehmensthema == null" class="alert alert-danger">
    Das Thema konnte nicht gefunden werden.

    <button type="button" class="btn btn-secondary me-3" @click="cancel()">Zurück</button>
  </div>

  <div v-else>
    <h1 v-if="editUnternehmensthema.id == 0">Neues Unternehmensthema</h1>
    <h1 v-else>Unternehmensthema bearbeiten</h1>
  </div>

  <hr class="my-4">

  <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

  <div v-else class="mt-3">
    <form @submit.prevent="saveUnternehmensthema()">
      <div class="bg-white shadow p-3 mb-3">
        <div class="d-flex mb-3">
          <div class="me-3 flex-fill">
            <label for="thema-kategorie" class="form-label">Kategorie</label>
            <input type="text" class="form-control" id="thema-kategorie" v-model="editUnternehmensthema.kategorie">
          </div>
          
          <div class="flex-fill">
            <label for="thema-thema" class="form-label">Thema</label>
            <input type="text" class="form-control" id="thema-nr" v-model="editUnternehmensthema.thema">
          </div>
        </div>
        
        <div class="mb-3">
          <label for="thema-beschreibung" class="form-label">Beschreibung</label>
          <TextEditor v-model="editUnternehmensthema.beschreibung" />
        </div>
      </div>

      <div v-if="errorMsg != ''" class="alert alert-danger my-3">
        Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.
      </div>

      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary me-3" @click="cancel()">Abbrechen</button>
        <button type="submit" class="btn btn-primary">Speichern</button>
      </div>
    </form>

    <hr class="my-4">

    <button v-if="editUnternehmensthema.id != 0 && ((editUnternehmensthema.unternehmenGuid == null && user.benutzerrolleId == 1) || editUnternehmensthema.unternehmenGuid != null)" type="button" class="btn btn-danger" @click="deleteUnternehmensthema()"><font-awesome-icon icon="trash" /> Unternehmensthema löschen</button>
  </div>

</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';


import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';
import { ActionTypes as UnternehmensthemaActionTypes } from '@/store/modules/Unternehmensthema/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import TextEditor from "@/components/TextEditor/TextEditor.vue"

import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

export default defineComponent({
  name: "EditUnternehmensthema",

  components: {
    LoadingSpinner,
    TextEditor
  },

  props: {
    unternehmensthemaid: {
      type: String as PropType<string>,
      default: ""
    },
    unternehmenguid: {
      type: String as PropType<string>,
      default: ""
    }
  },

  setup (props) {
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.getters.authUser?.benutzer);

    const loading = computed(() => store.getters.status.updateUnternehmensthemaLoading || store.getters.status.createUnternehmensthemaLoading || store.getters.status.deleteUnternehmensthemaLoading);
    const errorMsg = computed(() => store.getters.status.unternehmensthemaErrorMsg);

    const unternehmensthema = computed(() => store.getters.unternehmensthemen.find((f: Unternehmensthema) => f.id == Number.parseInt(props.unternehmensthemaid)));
    const editUnternehmensthema = ref<Unternehmensthema>(unternehmensthema.value != null ? JSON.parse(JSON.stringify(unternehmensthema.value)) as Unternehmensthema : new Unternehmensthema());

    function saveUnternehmensthema () {
      if (props.unternehmenguid != '') {
        editUnternehmensthema.value.unternehmenGuid = props.unternehmenguid;
      }

      if (editUnternehmensthema.value.id == 0) {
        store.dispatch(UnternehmensthemaActionTypes.CreateUnternehmensthema, editUnternehmensthema.value)
          .then(() => {
            if (errorMsg.value == '') {
              cancel()
            }
          });
      }
      else {
        store.dispatch(UnternehmensthemaActionTypes.UpdateUnternehmensthema, editUnternehmensthema.value)
          .then(() => {
            if (errorMsg.value == '') {
              cancel()
            }
          });
      }
    }

    function deleteUnternehmensthema () {
      store.dispatch(UnternehmensthemaActionTypes.DeleteUnternehmensthema, editUnternehmensthema.value.id)
        .then(() => {
          if (errorMsg.value == '') {
            cancel()
          }
        });
    }

    function cancel() {
      router.back();
    }

    return {
      user, 

      loading,
      errorMsg,

      unternehmensthema,
      editUnternehmensthema,

      saveUnternehmensthema,
      deleteUnternehmensthema,
      cancel
    }
  }
})
</script>