import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-90a84c96"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row py-2 mb-2 mx-1 bg-white shadow-sm rounded" }
const _hoisted_2 = { class: "col-4 my-auto" }
const _hoisted_3 = { class: "col-3 my-auto" }
const _hoisted_4 = { class: "col-3 my-auto" }
const _hoisted_5 = { class: "col-2 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.unternehmen.firmenname), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.unternehmen.firmennummer), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.unternehmen.ansprechpartner), 1),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_router_link, {
        to: {name: 'DetailsUnternehmen', params: { guid: _ctx.unternehmen.guid }},
        class: "btn btn-primary btn-sm m-0 float-end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}