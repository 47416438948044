import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-36fb5e75"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row py-2 mb-2 mx-1 bg-white shadow-sm umfrage-row rounded" }
const _hoisted_2 = { class: "col-3 my-auto" }
const _hoisted_3 = { class: "col-2 my-auto" }
const _hoisted_4 = { class: "col-2 my-auto" }
const _hoisted_5 = { class: "col-3 my-auto" }
const _hoisted_6 = { class: "col-2 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.umfrage.titel), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.startDatumConverted), 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.endDatumConverted), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.teilnehmer.length), 1),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_link, {
        to: { name: 'UnternehmenUmfrage', params: { unternehmenguid: _ctx.umfrage.unternehmenGuid, umfrageguid: _ctx.umfrage.guid }},
        class: "btn btn-primary btn-sm m-0 float-end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_font_awesome_icon, { icon: "chevron-right" })
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}