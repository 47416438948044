<template>
  <button class="btn btn-outline-secondary mb-2" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

  <div v-if="umfrage == null || unternehmen == null" class="alert alert-danger">
    <p>Die Umfrage oder das Unternehmen konnte nicht gefunden werden.</p>
  </div>

  <div v-else>
    <h1 class="mb-0">Stakeholder Zurordnung erstellen</h1>
    <small>{{ unternehmen.firmenname }} - {{ umfrage.titel }}</small>

    <hr class="my-4">

    <div v-for="(longlist, index) in umfrage.longlist.sort((a, b) => b.zustimmung - a.zustimmung)" :key="longlist.id" class="mb-3">
      <div class="row mx-1">
        <div class="col-4 bg-white shadow-sm p-3">
          <h3>{{ getTitel(longlist) }}</h3>
        </div>

        <div class="col border-top border-end border-bottom">
          <p class="text-center"><strong>Stakeholder</strong></p>

          <div v-for="item in stakeholder" :key="item.id" class="form-check d-flex align-items-center mb-2">
            <input class="form-check-input" type="checkbox" :id="'stakeholder-select-' + longlist.id + '-' + item.id" :value="item.id" v-model="selectedStakeholder[longlist.id]">
            <label class="form-check-label flex-fill ms-2" :for="'stakeholder-select-' + longlist.id + '-' + item.id">
              <StakeholderRow :stakeholder="item" :displayDetailsButton="false" class="stakeholder-select-row" :class="{ 'stakeholder-selected': selectedStakeholder[longlist.id].includes(item.id)}" />
            </label>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-4">

    <LoadingSpinner v-if="loading" text="... Stakeholder werden gespeichert ..." />

    <div v-else-if="errorMsg != ''" class="alert alert-danger">
      Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.
    </div>

    <div class="d-flex justify-content-end">
      <button type="button" class="btn btn-outline-secondary me-3" @click="cancel()" >Abbrechen</button>
      <button type="button" class="btn btn-primary" @click="saveSelectedStakeholder()">Speichern</button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { ActionTypes as UmfrageActionTypes } from '@/store/modules/Umfrage/actions';
import { ActionTypes as StakeholderActionTypes } from '@/store/modules/Stakeholder/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import StakeholderRow from '@/components/Stakeholder/StakeholderRow.vue';

import { Umfrage, LongList, LongListStakeholder, SetLongListStakeholderObject } from '@/models/UmfrageModels';
import { Unternehmen } from '@/models/UnternehmenModels';
import { Stakeholder } from '@/models/StakeholderModels';
import { Fragenkatalog } from '@/models/FragenkatalogModels';
import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

export default defineComponent({
  name: 'EditUmfrageStakeholderZuordnungView',

  components: {
    LoadingSpinner,
    StakeholderRow,
  },

  props: {
    unternehmenguid: {
      type: String as PropType<string>,
      required: true
    },

    umfrageguid: {
      type: String as PropType<string>,
      required: true
    },
  },

  setup(props) {
    const store = useStore()
    const router = useRouter()

    const loading = computed<boolean>(() => store.getters.status.updateLonglistLoading)
    const errorMsg = computed<string>(() => store.getters.status.longlistErrorMsg)

    const umfrage = computed<Umfrage>(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid))
    const unternehmen = computed<Unternehmen>(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid))
    const stakeholder = computed<Array<Stakeholder>>(() => store.getters.stakeholder)

    const unternehmensthemen = computed<Array<Unternehmensthema>>(() => store.getters.unternehmensthemen)
    const fragenkatalog = computed<Array<Fragenkatalog>>(() => store.getters.fragenkatalog)

    function getTitel (item: LongList) {
      let response = "unbekannt"
      if (item.frageid != null) {
        const frage = fragenkatalog.value.find((f: Fragenkatalog) => f.id == item.frageid);
        if (frage != undefined) {
          response = `${frage.nr}. ${frage.frageDE}`;
        }
      }
      else if (item.themaid != null) {
        const thema = unternehmensthemen.value.find((t: Unternehmensthema) => t.id == item.themaid);
        if (thema != undefined) {
          response = `${thema.kategorie} - ${thema.thema}`;
        }
      }

      return response;
    }

    const selectedStakeholder = ref<any>({});
    if (umfrage.value != null) {
      selectedStakeholder.value = {}

      umfrage.value.longlist.forEach((item: LongList) => {
        if (item.stakeholder != null && item.stakeholder.length > 0) {
          selectedStakeholder.value[item.id] = item.stakeholder.map((s: LongListStakeholder) => s.stakeholderid)
        }
        else {
          selectedStakeholder.value[item.id] = new Array<number>()
        }
      })
    }

    function saveSelectedStakeholder() {

      const longListUpdateData = new Array<LongList>();
      
      for (var key in selectedStakeholder.value) {
        const longlist = umfrage.value.longlist.find((item: LongList) => item.id == parseInt(key));
        const data = new Array<LongListStakeholder>()
        for (var stakeholderid of selectedStakeholder.value[key]) {
          const stakeholderEntry = longlist?.stakeholder.find((s: LongListStakeholder) => s.stakeholderid == stakeholderid);
          if (stakeholderEntry != undefined) {
            data.push(stakeholderEntry)
          }
          else
          {
            data.push(new LongListStakeholder(parseInt(key), stakeholderid))
          }
        }

        if (longlist != undefined) {
          longlist.stakeholder = data;
          longListUpdateData.push(longlist)
        }
      }


      store.dispatch(UmfrageActionTypes.UpdateLonglistRange, longListUpdateData)
      .then(() => {
        if (errorMsg.value == "") {
          cancel()
        }
      })
    }

    function cancel() {
      router.back()
    }
    
    return {
      store,
      router,

      loading,
      errorMsg,

      umfrage,
      unternehmen,
      stakeholder,

      getTitel,
      
      selectedStakeholder,

      saveSelectedStakeholder,
      cancel,
    };
  },

  mounted() {
    if (this.stakeholder.length == 0) {
      this.store.dispatch(StakeholderActionTypes.GetStakeholder, undefined);
    }
  }
});
</script>

<style scoped>
.stakeholder-selected {
  background: #1b3c7d2d !important;
}

.stakeholder-select-row:hover {
  cursor: pointer;
  background: #1b3c7d2d !important;
}
</style>