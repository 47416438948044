import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row py-2 mb-2 mx-1 bg-white shadow-sm rounded" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "col-2 my-auto" }
const _hoisted_4 = { class: "col-2 my-auto" }
const _hoisted_5 = { class: "col-2 my-auto" }
const _hoisted_6 = { class: "col-1 my-auto text-end text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "col-5 my-auto border-end cut-text",
      "data-bs-toggle": "tooltip",
      "data-bs-placement": "top",
      title: _ctx.titel
    }, _toDisplayString(_ctx.titel), 9, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.longlist.zustimmung) + "%", 1),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.longlist.relevant), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.longlist.nichtRelevant), 1),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.longlist.themaid != null)
        ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
            key: 0,
            icon: "star"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}