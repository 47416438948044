<template>
  <div class="d-flex justify-content-between">
    <h1>Stakeholder</h1>

    <button class="btn" @click="loadStakeholder()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <LoadingSpinner v-if="loading" text="... Stakeholder werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Fragen aufgetreten. Bitte probieren Sie es später erneut.
  </div>

  <router-link v-if="user.benutzerrolleId == 1" :to="{name: 'EditStakeholder'}" class="btn btn-secondary"><font-awesome-icon icon="plus" /> Hinzufügen</router-link>

  <div v-if="!loading && errorMsg == '' && stakeholder.length > 0" class="mt-3">
    <div class="row mb-3">
      <div class="col-4 offset-4">
        <div class="input-group input-group-sm my-auto">
          <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
          <span class="input-group-text">
            <font-awesome-icon icon="magnifying-glass" class="p-1"/>
          </span>
        </div>
      </div>
    </div>

    <div class="row mx-1 mb-2 bg-white shadow-sm rounded">
      <div class="col py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Name</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'nameasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'namedesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('nameasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'nameasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('namedesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'namedesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-for="stakeholder in sortedStakeholder" :key="stakeholder.id">
      <StakeholderRow :stakeholder="stakeholder" class="mb-2" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && stakeholder.length == 0" class="alert alert-info mt-5">
    Es wurden keine Stakeholder gefunden.
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as StakeholderActionTypes } from '@/store/modules/Stakeholder/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import StakeholderRow from "@/components/Stakeholder/StakeholderRow.vue";

import { Stakeholder } from '@/models/StakeholderModels';

export default defineComponent({
  name: "Stakeholder",

  components: {
    LoadingSpinner,
    StakeholderRow
  },

  setup () {
    const store = useStore()

    const loading = computed(() => store.getters.status.getStakeholderLoading);
    const errorMsg = computed(() => store.getters.status.stakeholderErrorMsg);

    const user = computed(() => store.getters.authUser?.benutzer);

    const searchString = ref("")

    const sortByValue = ref("nameasc")
    function sortBy (param: string) {
      sortByValue.value = param
    }

    const stakeholder = computed(() => store.getters.stakeholder);
    const sortedStakeholder = computed(() => {
      return stakeholder.value
        .filter((a: Stakeholder) => a.name.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()))
        .sort((a: Stakeholder, b: Stakeholder) => {

          if (sortByValue.value == "nameasc" || sortByValue.value == "namedesc") {
            var nameComparison = a.name.trim().toLowerCase().localeCompare(b.name.trim().toLowerCase())
            if (sortByValue.value == "namedesc") {
              nameComparison = -1 * nameComparison
            }
            
            return nameComparison;
          }

          return 0;
        });
    })

    function loadStakeholder () {
      store.dispatch(StakeholderActionTypes.GetStakeholder, undefined);
    }

    return {
      loading,
      errorMsg,

      user,

      searchString,
      sortByValue,
      sortBy,

      stakeholder,
      sortedStakeholder,

      loadStakeholder
    }
  },

  mounted () {
    this.loadStakeholder()
  }
})
</script>

<style scoped>
.table-col:hover {
  background: rgb(216, 217, 222);
  cursor: pointer;
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>