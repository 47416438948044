<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-primary shadow-sm pt-2 ps-1 pe-3">
    <div class="container-fluid">

      <router-link v-if="isPublicPage && user == null" :to="{ name: 'Home' }" class="navbar-brand">Nachhaltigkeit</router-link>

      <router-link v-else-if="user != null && user.benutzerrolleId == 3 && user.benutzerUnternehmen.length > 0" :to="{ name: 'DetailsUnternehmen', params: {guid: user.benutzerUnternehmen[0].unternehmenGuid} }" class="navbar-brand">Nachhaltigkeit</router-link>
      
      <ul v-else class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <button class="btn text-white" @click="toggleSidebar()"><font-awesome-icon icon="bars" /></button>
        </li>
      </ul>


      <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li v-if="isPublicPage">
            <select class="form-select" id="language-select" v-model="selectedLanguage" @change="setLanguage()">
              <option value="de">Deutsch</option>
              <option value="en">Englisch</option>
            </select>
          </li>

          <li v-if="loggedin" class="nav-item dropdown my-auto ms-5 me-1">
            <!-- <img v-if="profilbild != null && profilbild.filecontentAsBase64 != ''" :src="'data:image/bmp;base64, ' + profilbild.filecontentAsBase64" alt="Profilbild" class="avatar me-2" /> -->
            <img src="@/assets/img/profilbild.png" alt="Benutzer Profilbild" class="avatar me-2" />

            <a
              class="nav-link dropdown-toggle d-inline pe-0"
              href="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >{{ user.vorname }} {{ user.name }}</a>
            <ul class="dropdown-menu m-0">
              <li>
                <button class="dropdown-item" @click="logout()">
                  <font-awesome-icon
                    icon="arrow-right-from-bracket"
                    class="me-3"
                  />
                  Abmelden
                </button>
              </li>

              <li v-if="user != null && user.benutzerrolleId == 3 && user.benutzerUnternehmen.length > 0">
                <router-link :to="{name: 'UnternehmenSettings', params: {guid: user.benutzerUnternehmen[0].unternehmenGuid}}" class="dropdown-item">
                  <font-awesome-icon
                    icon="gear"
                    class="me-3"
                  />
                  Einstellungen
                </router-link>
              </li>
            </ul>
          </li>

          <li v-else-if="!loggedin && !isOnLoginPage" class="nav-item my-auto ms-5">
            <router-link :to="{ name: 'Login' }" class="nav-link"><font-awesome-icon icon="arrow-right-to-bracket" class="me-2" /> Anmelden</router-link>
          </li>
        </ul>
      </div>

    </div>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { ActionTypes as CommonActionTypes } from "@/store/modules/Common/actions"
import { ActionTypes as AuthActionTypes } from "@/store/modules/Auth/actions"
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Navbar",

  setup () {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const user = computed(() => store.getters.authUser?.benutzer);
    const loggedin = computed(() => store.getters.authUser != null);

    const publicPages = store.getters.publicPages;
    const isPublicPage = computed(() => route.name != null && route.name != "" && publicPages.includes(route.name.toString()))
    const isOnLoginPage = computed(() => route.name != null && route.name != "" && (route.name.toString() == "Login" || route.name.toString() == "Umfrage"))

    const language = computed((() => store.getters.language))
    const selectedLanguage = ref(store.getters.language)

    function setLanguage() {
      store.dispatch(CommonActionTypes.SetLanguage, selectedLanguage.value)
    }

    return {
      store,
      router,
      route,
      user,
      loggedin,
      isPublicPage,
      isOnLoginPage,
      language,
      selectedLanguage,
      setLanguage
    }
  },

  methods: {
    logout () {
      this.store.dispatch(AuthActionTypes.Logout, undefined)
      .then(() => {
        this.router.replace({name: "Logout"})
      })
    },

    toggleSidebar () {
      this.store.dispatch(CommonActionTypes.ToggleSidebar, undefined);
    },
  }


})
</script>

<style lang="scss" scoped>
img.avatar {
  object-fit: cover;
  border-radius: 50%;
  // border: 3px solid #212529;
  border: 3px solid #fff;
  height: 45px;
  width: 45px;
}
</style>