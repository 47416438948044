<template>
  <div class="d-flex justify-content-between align-items-center">
    <button type="button" class="btn btn-outline-secondary" @click="cancel()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

    <router-link v-if="stakeholder != null && user.benutzerrolleId == 1" :to="{name: 'EditStakeholder', query: {stakeholderid: stakeholderid}}" class="btn"><font-awesome-icon icon="pen" /></router-link>

  </div>

  <div v-if="stakeholder == null" class="alert alert-danger">
    Der Stakeholder konnte nicht gefunden werden.
  </div>

  <div v-else class="mt-3 p-3 bg-white shadow">
    <h1>{{ stakeholder.name}}</h1>

    <p>{{ stakeholder.beschreibung }}</p>
  </div>

</template>


<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { Stakeholder } from '@/models/StakeholderModels';

export default defineComponent({
  name: "DetailsStakeholderView",

  props: {
    stakeholderid: {
      type: String as PropType<string>,
      required: true
    }
  },

  setup (props) {
    const store = useStore()
    const router = useRouter()

    const loading = computed(() => store.getters.status.updateStakeholderLoading);
    const errorMsg = computed(() => store.getters.status.stakeholderErrorMsg);

    const user = computed(() => store.getters.authUser?.benutzer);

    const stakeholder = computed<Stakeholder>(() => store.getters.stakeholder.find((f: Stakeholder) => f.id == Number.parseInt(props.stakeholderid)));

    function cancel() {
      router.back();
    }

    return {
      loading,
      errorMsg,

      user,
      
      stakeholder,

      cancel
    }
  }
})
</script>