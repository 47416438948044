import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from '@/views/Home/HomeView.vue'
import Register from '@/views/Auth/RegisterView.vue'
import Login from '@/views/Auth/LoginView.vue'
import Logout from '@/views/Auth/LogoutView.vue'

import Dashboard from '@/views/Dashboard/DashboardView.vue'
import Benutzer from '@/views/Dashboard/BenutzerView.vue'

import Unternehmen from '@/views/Dashboard/Unternehmen/UnternehmenView.vue'
import DetailsUnternehmen from '@/views/Dashboard/Unternehmen/DetailsUnternehmenView.vue'
import EditUnternehmen from '@/views/Dashboard/Unternehmen/EditUnternehmenView.vue'
import UnternehmenSettings from '@/views/Dashboard/Unternehmen/UnternehmenSettingsView.vue'
import UnternehmenUmfrage from '@/views/Dashboard/Unternehmen/Umfrage/UnternehmenUmfrageView.vue'
import UmfrageTeilnehmer from '@/views/Dashboard/Unternehmen/Umfrage/UmfrageTeilnehmerView.vue'
import UmfrageERNA from '@/views/Dashboard/Unternehmen/Umfrage/UmfrageERNAView.vue'
import UmfrageLongList from '@/views/Dashboard/Unternehmen/Umfrage/UmfrageLongListView.vue'
import EditUmfrageLongList from '@/views/Dashboard/Unternehmen/Umfrage/EditUmfrageLongListView.vue'
import UmfrageStakeholderZuordnung from '@/views/Dashboard/Unternehmen/Umfrage/UmfrageStakeholderZuordnungView.vue'
import EditUmfrageStakeholderZuordnung from '@/views/Dashboard/Unternehmen/Umfrage/EditUmfrageStakeholderZuordnungView.vue'
import SelectUnternehmensthemen from '@/views/Dashboard/Unternehmen/Umfrage/SelectUnternehmensthemen.vue'

import Fragenkatalog from '@/views/Dashboard/Fragenkatalog/FragenkatalogView.vue'
import DetailsFragenkatalog from '@/views/Dashboard/Fragenkatalog/DetailsFragenkatalogView.vue'
import EditFragenkatalog from '@/views/Dashboard/Fragenkatalog/EditFragenkatalogView.vue'

import Unternehmensthemen from '@/views/Dashboard/Unternehmensthemen/UnternehmensthemenView.vue'
import DetailsUnternehmensthema from '@/views/Dashboard/Unternehmensthemen/DetailsUnternehmensthemaView.vue'
import EditUnternehmensthema from '@/views/Dashboard/Unternehmensthemen/EditUnternehmensthemaView.vue'

import Stakeholder from '@/views/Dashboard/Stakeholder/StakeholderView.vue'
import DetailsStakeholder from '@/views/Dashboard/Stakeholder/DetailsStakeholderView.vue'
import EditStakeholder from '@/views/Dashboard/Stakeholder/EditStakeholderView.vue'

import Umfrage from '@/views/Umfrage/UmfrageView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/start',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: Register
  // },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },

  //#region Fragenkatalog
  {
    path: '/dashboard/fragenkatalog',
    name: 'Fragenkatalog',
    component: Fragenkatalog
  },
  {
    path: '/dashboard/fragenkatalog/details/:fragenkatalogid',
    name: 'DetailsFragenkatalog',
    component: DetailsFragenkatalog,
    props: true
  },
  {
    path: '/dashboard/fragenkatalog/edit',
    name: 'EditFragenkatalog',
    component: EditFragenkatalog,
    props: route => ({ fragenkatalogid: route.query.fragenkatalogid })
  },
  //#endregion

  //#region Unternehmensthemen
  {
    path: '/dashboard/unternehmensthemen',
    name: 'Unternehmensthemen',
    component: Unternehmensthemen
  },
  {
    path: '/dashboard/unternehmensthema/details/:unternehmensthemaid',
    name: 'DetailsUnternehmensthema',
    component: DetailsUnternehmensthema,
    props: true
  },
  {
    path: '/dashboard/unternehmensthema/edit',
    name: 'EditUnternehmensthema',
    component: EditUnternehmensthema,
    props: route => ({ unternehmensthemaid: route.query.unternehmensthemaid, unternehmenguid: route.query.unternehmenguid })
  },
  //#endregion

  //#region Stakeholder
  {
    path: '/dashboard/stakeholder',
    name: 'Stakeholder',
    component: Stakeholder
  },
  {
    path: '/dashboard/stakeholder/details/:stakeholderid',
    name: 'DetailsStakeholder',
    component: DetailsStakeholder,
    props: true
  },
  {
    path: '/dashboard/stakeholder/edit',
    name: 'EditStakeholder',
    component: EditStakeholder,
    props: route => ({ stakeholderid: route.query.stakeholderid })
  },
  //#endregion

  {
    path: '/dashboard/benutzer',
    name: 'Benutzer',
    component: Benutzer
  },

  //#region Unternehmen
  {
    path: '/dashboard/unternehmen',
    name: 'Unternehmen',
    component: Unternehmen
  },
  {
    path: '/dashboard/unternehmen/:guid',
    name: 'DetailsUnternehmen',
    component: DetailsUnternehmen,
    props: true
  },
  {
    path: '/dashboard/unternehmen/edit',
    name: 'EditUnternehmen',
    component: EditUnternehmen,
    props: route => ({ unternehmenguid: route.query.unternehmenguid })
  },
  {
    path: '/dashboard/unternehmen/:guid/settings',
    name: 'UnternehmenSettings',
    component: UnternehmenSettings,
    props: true
  },
  {
    path: '/dashboard/unternehmen/:unternehmenguid/umfrage/:umfrageguid',
    name: 'UnternehmenUmfrage',
    component: UnternehmenUmfrage,
    props: true
  },
  {
    path: '/dashboard/unternehmen/:unternehmenguid/umfrage/:umfrageguid/unternehmensthemen',
    name: 'SelectUnternehmensthemen',
    component: SelectUnternehmensthemen,
    props: true
  },
  {
    path: '/dashboard/unternehmen/:unternehmenguid/umfrage/:umfrageguid/teilnehmer',
    name: 'UmfrageTeilnehmer',
    component: UmfrageTeilnehmer,
    props: true
  },
  {
    path: '/dashboard/unternehmen/:unternehmenguid/umfrage/:umfrageguid/erna',
    name: 'UmfrageERNA',
    component: UmfrageERNA,
    props: true
  },
  {
    path: '/dashboard/unternehmen/:unternehmenguid/umfrage/:umfrageguid/longlist',
    name: 'UmfrageLongList',
    component: UmfrageLongList,
    props: true
  },
  {
    path: '/dashboard/unternehmen/:unternehmenguid/umfrage/:umfrageguid/longlist/edit',
    name: 'EditUmfrageLongList',
    component: EditUmfrageLongList,
    props: true
  },
  {
    path: '/dashboard/unternehmen/:unternehmenguid/umfrage/:umfrageguid/stakeholder',
    name: 'UmfrageStakeholderZuordnung',
    component: UmfrageStakeholderZuordnung,
    props: true
  },
  {
    path: '/dashboard/unternehmen/:unternehmenguid/umfrage/:umfrageguid/stakeholder/edit',
    name: 'EditUmfrageStakeholderZuordnung',
    component: EditUmfrageStakeholderZuordnung,
    props: true
  },
  //#endregion
  
  {
    path: '/umfrage',
    name: 'Umfrage',
    component: Umfrage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import { useStore } from '@/store'

router.beforeEach((to, from, next) => {
  console.log(from);
  // const publicPages = ['/start', '/register', '/login', '/logout', '/umfrage'];
  const store = useStore();
  let availablePages = store.getters.publicPages;

  const authUser = store.getters.authUser

  if (authUser != null && authUser.benutzer.benutzerrolleId == 3) {
    availablePages = store.getters.userPages;
  }
  else if (authUser != null && authUser.benutzer.benutzerrolleId == 2) {
    availablePages = store.getters.beraterPages;
  }
  else if (authUser != null && authUser.benutzer.benutzerrolleId == 1) {
    availablePages = store.getters.adminPages;
  }

  console.log(availablePages);
  
  // const hasAccess = !availablePages.includes(to.name as string);
  if (availablePages.includes(to.name as string)) {
    console.log("available");
    next();
  }
  else if (from.name != null) {
    console.log("return to " + (from.name as string));
    next(from);
  }
  else {
    console.log("return to Home");
    next({name: 'Home'});
  }

  // next();


});

export default router
