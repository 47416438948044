import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row py-2 mx-1 bg-white shadow-sm rounded" }
const _hoisted_2 = { class: "col my-auto" }
const _hoisted_3 = {
  key: 0,
  class: "col-1 my-auto text-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.stakeholder.name), 1),
    (_ctx.displayDetailsButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: {name: 'DetailsStakeholder', params: { stakeholderid: _ctx.stakeholder.id.toString()}},
            class: "btn btn-primary btn-sm"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "magnifying-glass-plus" })
            ]),
            _: 1
          }, 8, ["to"])
        ]))
      : _createCommentVNode("", true)
  ]))
}