<template>
  <button class="btn btn-outline-secondary mb-2" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

  <h1 class="mb-0">Teilnehmer</h1>
  <small>{{ unternehmen.firmenname }} - {{ umfrage.titel }}</small>

  <LoadingSpinner v-if="teilnehmerantwortenloading" text="... Umfragedaten werden geladen ..." />

  <div class="bg-white shadow-sm p-3 my-4">
    <p><strong>Anzahl Teilnehmer:</strong> {{ teilnehmerliste.length }}</p>
    <p><strong>Anzahl beantworteter Fragen:</strong> {{ teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length }} von {{ teilnehmerliste.length * fragenkatalog.length }} (Anzahl Teilnehmer {{ teilnehmerliste.length }} x Anzahl Fragen {{ fragenkatalog.length }})</p>
  </div>

  <hr class="my-4">

  <h4>Teilnehmer</h4>

  <div class="row">
    <div class="col-2">
      <CreateTeilnehmerModal :umfrageguid="umfrageguid" />
    </div>
  </div>

  <hr class="my-4">
    
  <div class="row py-1 mb-1 mx-1">
    <div class="col-3 my-auto"><strong>Name</strong></div>
    <div class="col-2 my-auto"><strong>E-Mail</strong></div>
    <div class="col-2 my-auto"><strong>Telefon</strong></div>
    <div class="col-2 my-auto"><strong>Beantwortet</strong></div>
    <div class="col-1 my-auto"><strong>Dauer</strong></div>
    <div class="col-2 my-auto"></div>
  </div>

  <UmfrageTeilnehmerRow v-for="teilnehmer in teilnehmerliste" :key="teilnehmer.id" :teilnehmer="teilnehmer" :teilnehmer-antworten="teilnehmerantworten.filter((f: TeilnehmerAntwort) => f.teilnehmerGuid == teilnehmer.guid)" />

  <hr class="my-4">


  <!-- <div v-else>
    {{ teilnehmerantworten }}
  </div> -->

</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

import { ActionTypes as TeilnehmerAntwortActionTypes } from '@/store/modules/TeilnehmerAntwort/actions';
import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import UmfrageTeilnehmerRow from "@/components/Umfrage/UmfrageTeilnehmerRow.vue";
import CreateTeilnehmerModal from '@/components/Umfrage/CreateTeilnehmerModal.vue';


import { Umfrage } from '@/models/UmfrageModels';
import { Unternehmen } from '@/models/UnternehmenModels';
import { TeilnehmerAntwort } from '@/models/TeilnehmerAntwortModels';
import { Teilnehmer } from '@/models/TeilnehmerModels';

export default defineComponent({
  name: "UmfrageTeilnehmerView",

  components: {
    LoadingSpinner,
    UmfrageTeilnehmerRow,
    CreateTeilnehmerModal
  },

  props: {
    unternehmenguid: {
      type: String,
      required: true
    },

    umfrageguid: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter();
    
    const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid))
    const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid))
    const teilnehmerliste = computed(() => store.getters.teilnehmerliste.filter((t: Teilnehmer) => t.umfrageGuid == props.umfrageguid))
    const teilnehmerantworten = computed(() => store.getters.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => teilnehmerliste.value.map((t: Teilnehmer) => t.guid).includes(ta.teilnehmerGuid)))
    const fragenkatalog = computed(() => store.getters.fragenkatalog)

    const teilnehmerantwortenloading = ref(false);
    function getTeilnehmerantworten() {
      teilnehmerantwortenloading.value = true
      store.dispatch(TeilnehmerAntwortActionTypes.ClearTeilnehmerantwort, undefined);
      const promises = [];
      for (var teilnehmer of teilnehmerliste.value) {
        const promise = store.dispatch(TeilnehmerAntwortActionTypes.GetTeilnehmerantwortByTeilnehmerguid, teilnehmer.guid)
        promises.push(promise)
      }

      Promise.all(promises)
      .then(() => {
        // All requests have completed
        // console.log("All requests finished successfully.");
        teilnehmerantwortenloading.value = false
      })
    
    }

    return {
      store,
      router,
      unternehmen,
      umfrage,
      teilnehmerliste,
      teilnehmerantworten,
      teilnehmerantwortenloading,
      fragenkatalog,
      getTeilnehmerantworten
    }
  },

  mounted () {
    if (this.teilnehmerantworten.length == 0) {
      this.getTeilnehmerantworten()
    }

    if (this.fragenkatalog.length == 0) {
      this.store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
    }
  }

})
</script>