export class Umfrage {
  guid: string;
  unternehmenGuid: string;
  titel: string;
  startdatum: Date | null;
  enddatum: Date | null;
  isDeleted: number;

  longlist: Array<LongList>;

  constructor() {
    this.guid = "";
    this.unternehmenGuid = "";
    this.titel = "";

    this.startdatum = new Date();
    this.enddatum = new Date(this.startdatum.getTime() + (14 * 24 * 60 * 60 * 1000));
    
    this.isDeleted = 0;

    this.longlist = new Array<LongList>();
  }
}

export class LongList {
  id: number;
  umfrageGuid: string;
  frageid: number | null;
  themaid: number | null;

  zustimmung: number;

  beantwortet: number
  relevant: number
  nichtRelevant: number

  insideOut: number
  outsideIn: number
  bothWays: number
  richtungDontKnow: number

  shortTerm: number
  mediumTerm: number
  longTerm: number
  termDontKnow: number

  stakeholder: Array<LongListStakeholder>;

  constructor(umfrageGuid: string, frageid: number | null, themaid: number | null, zustimmung: number) {
    this.id = 0;
    
    this.umfrageGuid = umfrageGuid;
    
    this.frageid = frageid;
    this.themaid = themaid;

    this.zustimmung = zustimmung;

    this.beantwortet = 0;
    this.relevant = 0;
    this.nichtRelevant = 0;

    this.insideOut = 0;
    this.outsideIn = 0;
    this.bothWays = 0;
    this.richtungDontKnow = 0;

    this.shortTerm = 0;
    this.mediumTerm = 0;
    this.longTerm = 0;
    this.termDontKnow = 0;

    this.stakeholder = new Array<LongListStakeholder>();
  }
}

export class LongListStakeholder {
  id: number;
  longlistid: number;
  stakeholderid: number;

  constructor(longlistId: number, stakeholderId: number) {
    this.id = 0;
    this.longlistid = longlistId;
    this.stakeholderid = stakeholderId;
  }
}

export class SetLongListStakeholderObject {
  umfrageGuid: string;
  stakeholder: Array<LongListStakeholder>;

  constructor(umfrageGuid: string, stakeholder: Array<LongListStakeholder>) {
    this.umfrageGuid = umfrageGuid;
    this.stakeholder = stakeholder;
  }
}