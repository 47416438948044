<template>
  <div class="d-flex justify-content-between">
    <h1>Fragenkatalog</h1>

    <button class="btn" @click="loadFragenkatalog()"><font-awesome-icon icon="rotate" /></button>
  </div>

  <LoadingSpinner v-if="loading" text="... Fragen werden geladen ..." />
  
  <div v-else-if="errorMsg != ''" class="alert alert-danger mt-5">
    Es ist ein Fehler beim Laden der Fragen aufgetreten. Bitte probieren Sie es später erneut.
  </div>

  <div v-if="!loading && errorMsg == '' && fragenkatalog.length > 0" class="mt-3">
    <div class="row mb-3">
      <div class="col-4 offset-4">
        <div class="input-group input-group-sm my-auto">
          <input v-model.trim="searchString" type="search" class="form-control" id="searchInput" placeholder="Suche...">
          <span class="input-group-text">
            <font-awesome-icon icon="magnifying-glass" class="p-1"/>
          </span>
        </div>
      </div>
    </div>

    <div class="row mx-1 mb-2 bg-white shadow-sm rounded">
      <div class="col-1 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Nr.</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'nrasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'nrdesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('nrasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'nrasc'" />
                </div>
                <div class="col">
                  0 - 9
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('nrdesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'nrdesc'" />
                </div>
                <div class="col">
                  9 - 0
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-1 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Quelle</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'quelleasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'quelledesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('quelleasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'quelleasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('quelledesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'quelledesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-1 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Aspekt</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'aspektasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'aspektdesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('aspektasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'aspektasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('aspektdesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'aspektdesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-4 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Thema</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'themaasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'themadesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('themaasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'themaasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('themadesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'themadesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-5 py-2 table-col">
        <div class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          <strong>Frage</strong>

          <font-awesome-icon icon="arrow-down-long" v-show="sortByValue == 'frageasc'" class="text-secondary table-sort-icon"/>
          <font-awesome-icon icon="arrow-up-long" v-show="sortByValue == 'fragedesc'" class="text-secondary table-sort-icon"/>

          <ul class="dropdown-menu m-0 ms-1 p-0 rounded-0">
            <li class="dropdown-item select-sort" @click="sortBy('frageasc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'frageasc'" />
                </div>
                <div class="col">
                  A - Z
                </div>
              </div>
            </li>
            <li class="dropdown-item select-sort" @click="sortBy('fragedesc')">
              <div class="row">
                <div class="col-2">
                  <font-awesome-icon icon="check" v-show="sortByValue == 'fragedesc'" />
                </div>
                <div class="col">
                  Z - A
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-for="frage in sortedFragenkatalog" :key="frage.id">
      <FragenkatalogRow :fragenkatalog="frage" />
    </div>
  </div>

  <div v-else-if="!loading && errorMsg == '' && fragenkatalog.length == 0" class="alert alert-info mt-5">
    Es wurden keine Fragen gefunden.
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import FragenkatalogRow from "@/components/Fragenkatalog/FragenkatalogRow.vue";

import { Fragenkatalog } from '@/models/FragenkatalogModels';

export default defineComponent({
  name: "Fragenkatalog",

  components: {
    LoadingSpinner,
    FragenkatalogRow
  },

  setup () {
    const store = useStore()

    const loading = computed(() => store.getters.status.getFragenkatalogLoading);
    const errorMsg = computed(() => store.getters.status.fragenkatalogErrorMsg);

    const searchString = ref("")

    const sortByValue = ref("nrasc")
    function sortBy (param: string) {
      sortByValue.value = param
    }

    const fragenkatalog = computed(() => store.getters.fragenkatalog);
    const sortedFragenkatalog = computed(() => {
      return fragenkatalog.value
        .filter((a: Fragenkatalog) => a.nr.toString().includes(searchString.value) ||
          a.quelle.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          a.aspektDE.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          a.themaDE.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()) ||
          a.frageDE.trim().toLowerCase().includes(searchString.value.trim().toLowerCase()))
        .sort((a: Fragenkatalog, b: Fragenkatalog) => {
          if (sortByValue.value == "nrasc" || sortByValue.value == "nrdesc") {
            if (sortByValue.value == "nrasc") {
              return a.nr - b.nr
            }
            else {
              return b.nr - a.nr
            }
          }

          else if (sortByValue.value == "quelleasc" || sortByValue.value == "quelledesc") {
            var quelleComparison = a.quelle.trim().toLowerCase().localeCompare(b.quelle.trim().toLowerCase())
            if (sortByValue.value == "quelledesc") {
              quelleComparison = -1 * quelleComparison
            }
            
            if (quelleComparison == 0) {
              return a.nr - b.nr
            }
            
            return quelleComparison;
          }

          else if (sortByValue.value == "aspektasc" || sortByValue.value == "aspektdesc") {
            var aspektComparison = a.aspektDE.trim().toLowerCase().localeCompare(b.aspektDE.trim().toLowerCase())
            if (sortByValue.value == "aspektdesc") {
              aspektComparison = -1 * aspektComparison
            }
            
            if (aspektComparison == 0) {
              return a.nr - b.nr
            }
            
            return aspektComparison;
          }

          else if (sortByValue.value == "themaasc" || sortByValue.value == "themadesc") {
            var themaComparison = a.themaDE.trim().toLowerCase().localeCompare(b.themaDE.trim().toLowerCase())
            if (sortByValue.value == "themadesc") {
              themaComparison = -1 * themaComparison
            }
            
            if (themaComparison == 0) {
              return a.nr - b.nr
            }
            
            return themaComparison;
          }

          else if (sortByValue.value == "frageasc" || sortByValue.value == "fragedesc") {
            var frageComparison = a.frageDE.trim().toLowerCase().localeCompare(b.frageDE.trim().toLowerCase())
            if (sortByValue.value == "fragedesc") {
              frageComparison = -1 * frageComparison
            }
            
            if (frageComparison == 0) {
              return a.nr - b.nr
            }
            
            return frageComparison;
          }

          return 0;
        });
    })

    function loadFragenkatalog () {
      store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
    }

    return {
      loading,
      errorMsg,

      searchString,
      sortByValue,
      sortBy,

      fragenkatalog,
      sortedFragenkatalog,

      loadFragenkatalog
    }
  },

  mounted () {
    this.loadFragenkatalog()
  }
})
</script>

<style scoped>
.table-col:hover {
  background: rgb(216, 217, 222);
  cursor: pointer;
}

.table-sort-icon {
  font-size: .7em;
}

.select-sort:hover {
  cursor: pointer;
}
</style>