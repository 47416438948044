export class Status {
  errorMsg: string;
  loading: boolean;

  publicPages: Array<string> = ["Home", "Umfrage", "Login", "Logout", "Register"];
  userPages: Array<string> = [
    "DetailsUnternehmen",
    "EditUnternehmen",
    "UnternehmenSettings",
    "UnternehmenUmfrage",
    "UmfrageTeilnehmer",
    "UmfrageERNA",
    "UmfrageLongList",
    "EditUmfrageLongList",
    "UmfrageStakeholderZuordnung",
    "EditUmfrageStakeholderZuordnung",
    "Unternehmensthemen",
    "DetailsUnternehmensthema",
    "EditUnternehmensthema",
    "SelectUnternehmensthemen"
  ];
  beraterPages: Array<string> = ["Unternehmen", "Fragenkatalog", "DetailsFragenkatalog", "Stakeholder", "DetailsStakeholder"];
  adminPages: Array<string> = ["Dashboard", "EditFragenkatalog", "EditStakeholder", "Benutzer"];

  sidebarCollapsed: boolean;
  authLoginLoading: boolean
  authLogoutLoading: boolean
  authRegisterLoading: boolean
  authErrorMsg: string

  //#region Benutzer
  getBenutzerLoading: boolean;
  createBenutzerLoading: boolean;
  updateBenutzerLoading: boolean;
  deleteBenutzerLoading: boolean;
  benutzerErrorMsg: string;
  //#endregion
  
  //#region Benutzerrolle
  getBenutzerrolleLoading: boolean;
  benutzerrolleErrorMsg: string;
  //#endregion

  //#region Benutzer
  getBenutzerUnternehmenLoading: boolean;
  createBenutzerUnternehmenLoading: boolean;
  updateBenutzerUnternehmenLoading: boolean;
  deleteBenutzerUnternehmenLoading: boolean;
  benutzerUnternehmenErrorMsg: string;
  //#endregion

  //#region Fragenkatalog
  getFragenkatalogLoading: boolean;
  createFragenkatalogLoading: boolean;
  updateFragenkatalogLoading: boolean;
  deleteFragenkatalogLoading: boolean;
  fragenkatalogErrorMsg: string;
  //#endregion

  //#region Longlist
  getLonglistLoading: boolean;
  createLonglistLoading: boolean;
  updateLonglistLoading: boolean;
  deleteLonglistLoading: boolean;
  longlistErrorMsg: string;
  //#endregion

  //#region LonglistStakeholder
  getLonglistStakeholderLoading: boolean;
  createLonglistStakeholderLoading: boolean;
  updateLonglistStakeholderLoading: boolean;
  deleteLonglistStakeholderLoading: boolean;
  longlistStakeholderErrorMsg: string;
  //#endregion

  //#region Stakeholder
  getStakeholderLoading: boolean;
  createStakeholderLoading: boolean;
  updateStakeholderLoading: boolean;
  deleteStakeholderLoading: boolean;
  stakeholderErrorMsg: string;
  //#endregion

  //#region Teilnehmer
  getTeilnehmerLoading: boolean;
  createTeilnehmerLoading: boolean;
  updateTeilnehmerLoading: boolean;
  deleteTeilnehmerLoading: boolean;
  emailTeilnehmerLoading: boolean;
  teilnehmerErrorMsg: string;
  //#endregion

  //#region TeilnehmerAntwort
  getTeilnehmerAntwortLoading: boolean;
  createTeilnehmerAntwortLoading: boolean;
  updateTeilnehmerAntwortLoading: boolean;
  deleteTeilnehmerAntwortLoading: boolean;
  teilnehmerAntwortErrorMsg: string;
  //#endregion

  //#region Umfrage
  getUmfrageLoading: boolean;
  createUmfrageLoading: boolean;
  updateUmfrageLoading: boolean;
  deleteUmfrageLoading: boolean;
  umfrageErrorMsg: string;
  //#endregion

  //#region Unternehmen
  getUnternehmenLoading: boolean;
  createUnternehmenLoading: boolean;
  updateUnternehmenLoading: boolean;
  deleteUnternehmenLoading: boolean;
  unternehmenErrorMsg: string;
  //#endregion

  //#region Unternehmensthema
  getUnternehmensthemaLoading: boolean;
  createUnternehmensthemaLoading: boolean;
  updateUnternehmensthemaLoading: boolean;
  deleteUnternehmensthemaoading: boolean;
  unternehmensthemaErrorMsg: string;
  //#endregion

  constructor () {
    this.errorMsg = "";
    this.loading = false;

    this.sidebarCollapsed = false;

    //#region Auth
    this.authLoginLoading = false;
    this.authLogoutLoading = false;
    this.authRegisterLoading = false;
    this.authErrorMsg = "";
    //#endregion

    //#region Benutzer
    this.getBenutzerLoading = false;
    this.createBenutzerLoading = false;
    this.updateBenutzerLoading = false;
    this.deleteBenutzerLoading = false;
    this.benutzerErrorMsg = "";
    //#endregion

    //#region Benutzerrolle
    this.getBenutzerrolleLoading = false;
    this.benutzerrolleErrorMsg = "";
    //#endregion

    //#region BenutzerUnternehmen
    this.getBenutzerUnternehmenLoading = false;
    this.createBenutzerUnternehmenLoading = false;
    this.updateBenutzerUnternehmenLoading = false;
    this.deleteBenutzerUnternehmenLoading = false;
    this.benutzerUnternehmenErrorMsg = "";
    //#endregion

    //#region Fragenkatalog
    this.getFragenkatalogLoading = false;
    this.createFragenkatalogLoading = false;
    this.updateFragenkatalogLoading = false;
    this.deleteFragenkatalogLoading = false;
    this.fragenkatalogErrorMsg = "";
    //#endregion

    //#region Longlist
    this.getLonglistLoading = false;
    this.createLonglistLoading = false;
    this.updateLonglistLoading = false;
    this.deleteLonglistLoading = false;
    this.longlistErrorMsg = "";
    //#endregion

    //#region LonglistStakeholder
    this.getLonglistStakeholderLoading = false;
    this.createLonglistStakeholderLoading = false;
    this.updateLonglistStakeholderLoading = false;
    this.deleteLonglistStakeholderLoading = false;
    this.longlistStakeholderErrorMsg = "";
    //#endregion

    //#region Stakeholder
    this.getStakeholderLoading = false;
    this.createStakeholderLoading = false;
    this.updateStakeholderLoading = false;
    this.deleteStakeholderLoading = false;
    this.stakeholderErrorMsg = "";
    //#endregion

    //#region Teilnehmer
    this.getTeilnehmerLoading = false;
    this.createTeilnehmerLoading = false;
    this.updateTeilnehmerLoading = false;
    this.deleteTeilnehmerLoading = false;
    this.emailTeilnehmerLoading = false;
    this.teilnehmerErrorMsg = "";
    //#endregion

    //#region TeilnehmerAntwort
    this.getTeilnehmerAntwortLoading = false;
    this.createTeilnehmerAntwortLoading = false;
    this.updateTeilnehmerAntwortLoading = false;
    this.deleteTeilnehmerAntwortLoading = false;
    this.teilnehmerAntwortErrorMsg = "";
    //#endregion

    //#region Umfrage
    this.getUmfrageLoading = false;
    this.createUmfrageLoading = false;
    this.updateUmfrageLoading = false;
    this.deleteUmfrageLoading = false;
    this.umfrageErrorMsg = "";
    //#endregion

    //#region Unternehmen
    this.getUnternehmenLoading = false;
    this.createUnternehmenLoading = false;
    this.updateUnternehmenLoading = false;
    this.deleteUnternehmenLoading = false;
    this.unternehmenErrorMsg = "";
    //#endregion

    //#region Unternehmensthema
    this.getUnternehmensthemaLoading = false;
    this.createUnternehmensthemaLoading = false;
    this.updateUnternehmensthemaLoading = false;
    this.deleteUnternehmensthemaoading = false;
    this.unternehmensthemaErrorMsg = "";
    //#endregion
  }
}

export interface ErrorResponseData {
  detail: string
  status: number
  title: string
  type: string
}