<template>
  <div class="row py-2 mb-2 mx-1 bg-white shadow-sm rounded">
    <div class="col-5 my-auto  border-end cut-text" data-bs-toggle="tooltip" data-bs-placement="top" :title="titel">{{ titel }}</div>
    <div class="col-2 my-auto">{{ longlist.zustimmung }}%</div>
    <div class="col-2 my-auto">{{ longlist.relevant }}</div>
    <div class="col-2 my-auto">{{ longlist.nichtRelevant }}</div>
    <div class="col-1 my-auto text-end text-muted">
      <font-awesome-icon v-if="longlist.themaid != null" icon="star" />
    </div>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

import { LongList } from '@/models/UmfrageModels';
import { Fragenkatalog } from '@/models/FragenkatalogModels';
import { Unternehmensthema } from '@/models/UnternehmensthemenModels';

export default defineComponent({
  name: "LongListRow",

  props: {
    longlist: {
      type: Object as PropType<LongList>,
      required: true
    },
  },

  setup(props) {
    const store = useStore()

    const titel = computed(() => {
      if (props.longlist.frageid != null) {
        const frage = store.getters.fragenkatalog.find((f: Fragenkatalog) => f.id == props.longlist.frageid);
        return `${frage.nr}. ${frage.frageDE}`;
      }
      else if (props.longlist.themaid != null) {
        const thema = store.getters.unternehmensthemen.find((t: Unternehmensthema) => t.id == props.longlist.themaid);
        return `${thema.kategorie} - ${thema.thema}`;
      }
      else {
        return "unbekannt";
      }
      
    })

    return {
      store,
      titel
    }
  },

  mounted () {
    if (this.store.getters.fragenkatalog.length == 0) {
      this.store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
    }
  }
})
</script>