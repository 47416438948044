<template>
  <div v-if="unternehmenguid != '' && unternehmen == null" class="alert alert-danger">
    Das Unternehmen konnte nicht gefunden werden.

    <button type="button" class="btn btn-secondary me-3" @click="cancel()">Zurück</button>
  </div>

  <div v-else>
    <h1 v-if="unternehmen == null">Neues Unternehmen</h1>
    <h1 v-else>{{ unternehmen.firmenname }} bearbeiten</h1>
  </div>

  <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

  <div v-else>
    <form @submit.prevent="saveUnternehmen()">
      <div class="row mx-1 mb-3">
        <div class="col bg-white shadow-sm me-1 p-3">
          <div class="mb-3">
            <label for="firmenname" class="form-label">Firmenname</label>
            <input type="text" class="form-control" id="firmenname" v-model="editUnternehmen.firmenname" required>
          </div>
  
          <div class="mb-3">
            <label for="land" class="form-label">Land</label>
            <input type="text" class="form-control" id="land" v-model="editUnternehmen.land">
          </div>

          <div class="mb-3">
            <label for="strasse" class="form-label">Straße</label>
            <input type="text" class="form-control" id="strasse" v-model="editUnternehmen.strasse">
          </div>
  
          <div class="mb-3">
            <label for="plz" class="form-label">PLZ</label>
            <input type="text" class="form-control" id="plz" v-model="editUnternehmen.plz">
          </div>
  
          <div class="mb-3">
            <label for="ort" class="form-label">Ort</label>
            <input type="text" class="form-control" id="ort" v-model="editUnternehmen.ort">
          </div>
        </div>

        <div class="col bg-white shadow-sm ms-1 p-3">
          <div class="mb-3">
            <label for="ansprechpartner" class="form-label">Ansprechpartner</label>
            <input type="text" class="form-control" id="ansprechpartner" v-model="editUnternehmen.ansprechpartner">
          </div>
  
          <div class="mb-3">
            <label for="ansprechpartneremail" class="form-label">Ansprechpartner E-Mail</label>
            <input type="email" class="form-control" id="ansprechpartneremail" v-model="editUnternehmen.ansprechpartnerEmail">
          </div>
  
          <div class="mb-3">
            <label for="ansprechpartnertel" class="form-label">Ansprechpartner Telefon</label>
            <input type="text" class="form-control" id="ansprechpartnertel" v-model="editUnternehmen.ansprechpartnerTel">
          </div>
        </div>
      </div>


      <div v-if="errorMsg != ''" class="alert alert-danger my-3">
        Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.
      </div>

      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-secondary me-3"
          @click="cancel()"
          >
          Abbrechen
        </button>
        
        <button
          type="submit"
          class="btn btn-primary"
          >
          Speichern
        </button>
      </div>
    </form>

    <div v-if="unternehmen != null && user != null && user.benutzerrolleId != 3">
      <hr class="my-4">
      
      <button type="button" class="btn btn-danger" @click="deleteUnternehmen()"><font-awesome-icon icon="trash" /> Unternehmen löschen</button>
    </div>

  </div>

</template>

<script lang="ts">
import { computed, defineComponent, ref, PropType } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

import { ActionTypes as UnternehmenActionTypes } from '@/store/modules/Unternehmen/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import UmfrageRow from "@/components/Umfrage/UmfrageRow.vue";

import { Unternehmen } from '@/models/UnternehmenModels';

export default defineComponent({
  name: "EditUnternehmenView",

  components: {
    LoadingSpinner,
    UmfrageRow
  },

  props: {
    unternehmenguid: {
      type: String as PropType<string>,
      default: ""
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter();

    const loading = computed(() => store.getters.status.createUnternehmenLoading || store.getters.status.updateUnternehmenLoading || store.getters.status.deleteUnternehmenLoading);
    const errorMsg = computed(() => store.getters.status.unternehmenErrorMsg);

    const user = computed(() => store.getters.authUser?.benutzer)
    
    const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid))
    const editUnternehmen = ref<Unternehmen>(unternehmen.value != null ? JSON.parse(JSON.stringify(unternehmen.value)) as Unternehmen : new Unternehmen())

    function saveUnternehmen() {
      if (editUnternehmen.value.guid == "") {
        store.dispatch(UnternehmenActionTypes.CreateUnternehmen, editUnternehmen.value)
          .then(() => {
            if (errorMsg.value == '') {
              router.push({name: 'Unternehmen'})
            }
          });
      }
      else {
        store.dispatch(UnternehmenActionTypes.UpdateUnternehmen, editUnternehmen.value)
          .then(() => {
            if (errorMsg.value == '') {
              cancel()
            }
          });
      }
    }

    function deleteUnternehmen() {
      store.dispatch(UnternehmenActionTypes.DeleteUnternehmen, editUnternehmen.value.guid)
        .then(() => {
          if (errorMsg.value == '') {
            router.push({name: 'Unternehmen'})
          }
        });
    }

    function cancel() {
      router.back();
    }

    return {
      router,
      
      loading,
      errorMsg,

      user,

      unternehmen,
      editUnternehmen,

      saveUnternehmen,
      deleteUnternehmen,
      cancel
    }
  },
})
</script>