<template>
  <button class="btn btn-outline-secondary mb-2" @click="router.back()"><font-awesome-icon icon="chevron-left" /> Zurück</button>

  <h1 class="mb-0">Umfrageergebnisse</h1>
  <small>{{ unternehmen.firmenname }} - {{ umfrage.titel }}</small>

  <LoadingSpinner v-if="teilnehmerantwortenloading" text="... Umfragedaten werden geladen ..." />

  <div class="bg-white shadow-sm p-3 my-4">
    <p><strong>Anzahl Teilnehmer:</strong> {{ teilnehmerliste.length }}</p>
    <p><strong>Anzahl beantworteter Fragen:</strong> {{ teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.beantwortet == 1).length }} von {{ teilnehmerliste.length * fragenkatalog.length }} (Anzahl Teilnehmer {{ teilnehmerliste.length }} x Anzahl Fragen {{ fragenkatalog.length }})</p>
  </div>

  <hr class="my-4">

  <div class="d-flex justify-content-between">
    <h4>Fragen</h4>
    
    <div class="dropdown">
      <button class="btn btn-outline-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><font-awesome-icon icon="file-arrow-down" class="me-1" /> Excel Export</button>
      <ul class="dropdown-menu">
        <li class="dropdown-item export-dropdown-item" @click="exportData('gesamt')">Alle Themen</li>
        <li class="dropdown-item export-dropdown-item" @click="exportData('insideout')">Inside-Out Themen</li>
        <li class="dropdown-item export-dropdown-item" @click="exportData('outsidein')">Outside-In Themen</li>
      </ul>
    </div>
  </div>

  <p>Häufigkeit, wie oft eine Frage wie beantwortet wurde.</p>

  <div class="row mb-2 mx-1">
    <div class="col-5 border-end"></div>
    <div class="col-3 fst-italic border-end">Wirkungsrichtung</div>
    <div class="col-3 fst-italic border-end">Wirkungszeitraum</div>
    <div class="col-1"></div>
  </div>

  <div class="row mb-1 mx-1">
    <div class="col-5">
      <div class="row">
        <div class="col"><strong>Frage</strong></div>
        <div class="col-3 border-end"><strong>Quelle</strong></div>
        <div class="col-2"><strong>Relevant</strong></div>
        <div class="col-3 border-end"><strong>Nicht Relevant</strong></div>
      </div>
    </div>

    <div class="col-3">
      <div class="row">
        <div class="col"><strong>InsideOut</strong></div>
        <div class="col"><strong>OutsideIn</strong></div>
        <div class="col"><strong>BothWays</strong></div>
        <div class="col-1 border-end"><strong>-</strong></div>
      </div>
    </div>

    <div class="col-3">
      <div class="row">
        <div class="col"><strong>ShortTerm</strong></div>
        <div class="col"><strong>MediumTerm</strong></div>
        <div class="col"><strong>LongTerm</strong></div>
        <div class="col-1 border-end"><strong>-</strong></div>
      </div>
    </div>

    <div class="col-1"><strong>Antworten</strong></div>
  </div>
  <div v-for="frage in fragenkatalog" :key="frage.id">
    <SummeUmfrageFrageRow :frage="frage" :antworten="teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id)" />
  </div>

</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import { writeFile as XLSXwriteFile,  utils as XLSXutils } from "xlsx";

import { ActionTypes as TeilnehmerAntwortActionTypes } from '@/store/modules/TeilnehmerAntwort/actions';
import { ActionTypes as FragenkatalogActionTypes } from '@/store/modules/Fragenkatalog/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';
import UmfrageTeilnehmerRow from "@/components/Umfrage/UmfrageTeilnehmerRow.vue";
import SummeUmfrageFrageRow from '@/components/Umfrage/SummeUmfrageFrageRow.vue';

import { Umfrage } from '@/models/UmfrageModels';
import { Unternehmen } from '@/models/UnternehmenModels';
import { TeilnehmerAntwort } from '@/models/TeilnehmerAntwortModels';
import { Teilnehmer } from '@/models/TeilnehmerModels';
import { Fragenkatalog } from '@/models/FragenkatalogModels';

export default defineComponent({
  name: "UmfrageERNAView",

  components: {
    LoadingSpinner,
    UmfrageTeilnehmerRow,
    SummeUmfrageFrageRow
  },

  props: {
    unternehmenguid: {
      type: String,
      required: true
    },

    umfrageguid: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const store = useStore()
    const router = useRouter();
    
    const unternehmen = computed(() => store.getters.unternehmenliste.find((u: Unternehmen) => u.guid == props.unternehmenguid))
    const umfrage = computed(() => store.getters.umfragen.find((u: Umfrage) => u.guid == props.umfrageguid))
    const teilnehmerliste = computed(() => store.getters.teilnehmerliste.filter((t: Teilnehmer) => t.umfrageGuid == props.umfrageguid))
    const teilnehmerantworten = computed(() => store.getters.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => teilnehmerliste.value.map((t: Teilnehmer) => t.guid).includes(ta.teilnehmerGuid)))
    const fragenkatalog = computed(() => store.getters.fragenkatalog)

    const teilnehmerantwortenloading = ref(false);

    function getTeilnehmerantworten() {
      teilnehmerantwortenloading.value = true
      store.dispatch(TeilnehmerAntwortActionTypes.ClearTeilnehmerantwort, undefined);
      const promises = [];
      for (var teilnehmer of teilnehmerliste.value) {
        const promise = store.dispatch(TeilnehmerAntwortActionTypes.GetTeilnehmerantwortByTeilnehmerguid, teilnehmer.guid)
        promises.push(promise)
      }

      Promise.all(promises)
      .then(() => {
        // All requests have completed
        // console.log("All requests finished successfully.");
        teilnehmerantwortenloading.value = false
      })
    
    }

    return {
      store,
      router,
      unternehmen,
      umfrage,
      teilnehmerliste,
      teilnehmerantworten,
      teilnehmerantwortenloading,
      fragenkatalog,
      getTeilnehmerantworten
    }
  },

  methods: {
    exportAsExcel(rows: any, docNameSuffix: string = "data") {
      /* generate worksheet and workbook */
      const worksheet = XLSXutils.json_to_sheet(rows);
      const workbook = XLSXutils.book_new();
      XLSXutils.book_append_sheet(workbook, worksheet, "Data");

      /* create an XLSX file and try to save to Presidents.xlsx */
      XLSXwriteFile(workbook, `${this.umfrage.guid}-${docNameSuffix}.xlsx`);
    },

    exportData(value: string = "gesamt") {
      // value: "gesamt" | "insideout" | "outsidein"

      const rows = [];

      for (var frage of this.fragenkatalog) {
        const row = this.getExcelRow(frage)
        rows.push(row)
      }

      if (value == "gesamt") {
        this.exportAsExcel(rows, "gesamt")
      }
      else if (value == "insideout") {
        const rowsInsideOut = rows.filter((row: any) => row.InsideOut > 0 || row.BothWays > 0)
        this.exportAsExcel(rowsInsideOut, "insideout")
      }
      else if (value == "outsidein") {
        const rowsOutsideIn = rows.filter((row: any) => row.OutsideIn > 0 || row.BothWays > 0)
        this.exportAsExcel(rowsOutsideIn, "outsidein")
      }
    },

    getExcelRow(frage: Fragenkatalog): any {
      const anzahlAntworten =  this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.beantwortet == 1).length
      const countNichtRelevant =  this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.nichtRelevant == 1 && ta.beantwortet == 1).length
      const countRelevant = anzahlAntworten - countNichtRelevant
      const countInsideOut =  this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.insideOut == 1 && ta.beantwortet == 1).length
      const countOutsideIn =  this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.outsideIn == 1 && ta.beantwortet == 1).length
      const countBothWays =  this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.bothWays == 1 && ta.beantwortet == 1).length
      const countRichtungDontKnow = this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.richtungDontKnow == 1 && ta.beantwortet == 1).length

      const countShortTerm =  this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.shortTerm == 1 && ta.beantwortet == 1).length
      const countMediumTerm =  this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.mediumTerm == 1 && ta.beantwortet == 1).length
      const countLongTerm =  this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.longTerm == 1 && ta.beantwortet == 1).length
      const countTermDontKnow = this.teilnehmerantworten.filter((ta: TeilnehmerAntwort) => ta.fragenkatalogid == frage.id && ta.termDontKnow == 1 && ta.beantwortet == 1).length


      const row = {
        Thema: frage.nr + " " + frage.frageDE,
        Quelle: frage.quelle,
        Aspekt: frage.aspektDE,
        Kategorie: frage.themaDE,
        Relevant: countRelevant,
        NichtRelevant: countNichtRelevant,
        InsideOut: countInsideOut,
        OutsideIn: countOutsideIn,
        BothWays: countBothWays,
        RichtungDontKnow: countRichtungDontKnow,
        ShortTerm: countShortTerm,
        MediumTerm: countMediumTerm,
        LongTerm: countLongTerm,
        TermDontKnow: countTermDontKnow,
        AnzahlAntworten: anzahlAntworten
      }

      return row;
    }
  },

  mounted () {
    if (this.teilnehmerantworten.length == 0) {
      this.getTeilnehmerantworten()
    }

    if (this.fragenkatalog.length == 0) {
      this.store.dispatch(FragenkatalogActionTypes.GetFragenkatalog, undefined);
    }
  }

})
</script>

<style scoped>
.export-dropdown-item {
  cursor: pointer;
}
</style>