import axios, { AxiosInstance, AxiosResponse } from "axios";

import { Benutzer, CreateBenutzerRequest, UpdateBenutzerPasswortRequest, BenutzerUnternehmen, BeraterUnternehmenRequest } from "@/models/BenutzerModels";
import { Fragenkatalog } from "@/models/FragenkatalogModels";
import { Teilnehmer, TeilnehmerEmail } from "@/models/TeilnehmerModels";
import { TeilnehmerAntwort } from "@/models/TeilnehmerAntwortModels";
import { LongList, LongListStakeholder, Umfrage } from "@/models/UmfrageModels";
import { Unternehmen } from "@/models/UnternehmenModels";
import { Stakeholder } from "@/models/StakeholderModels";
import { LoginRequest, RefreshLoginRequest, LogoutRequest, RegisterRequest } from "@/models/AuthModels";
import { Unternehmensthema } from "@/models/UnternehmensthemenModels";


/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json()

const BASE_URL = config.nachhaltigkeitsapi.apiUrl;
const API_KEY = config.nachhaltigkeitsapi.apiKey;

const apiClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
});

export default apiClient;

// const apiClient = setupInterceptorsTo(axios.create({
//   baseURL: BASE_URL,
//   withCredentials: false,
//   headers: {
//     Accept: 'application/json',
//     'Content-Type': 'application/json',
//     'ApiKey': API_KEY
//   }
// }));

export const ApiService = {

  //#region Auth
  login(data: LoginRequest): Promise<AxiosResponse> {
    return apiClient.post("/login", data)
  },
  
  refreshLogin(data: RefreshLoginRequest) {
    return apiClient.post("/login/refresh", data)
  },
  
  logout(data: LogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/logout', data);
  },

  register(data: RegisterRequest): Promise<AxiosResponse> {
    return apiClient.post('/register', data)
  },
  //#endregion
  
  //#region Benutzer
  GetAllBenutzer(unternehmenGuid: string = ""): Promise<AxiosResponse> {
    if (unternehmenGuid == "") {
      return apiClient.get('/benutzer')
    }
    else {
      return apiClient.get(`/benutzer?unternehmenGuid=${unternehmenGuid}`)
    }
  },

  GetBenutzerById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/benutzer/${id}`)
  },

  CreateBenutzer(data: CreateBenutzerRequest): Promise<AxiosResponse> {
    return apiClient.post('/benutzer', data)
  },

  UpdateBenutzer(data: Benutzer): Promise<AxiosResponse> {
    return apiClient.put('/benutzer', data)
  },

  UpdateBenutzerPasswort(data: UpdateBenutzerPasswortRequest): Promise<AxiosResponse> {
    return apiClient.put('/benutzer/passwort', data)
  },

  DeleteBenutzer(id: number): Promise<AxiosResponse> {
    return apiClient.delete(`/benutzer/${id}/final`)
  },

  GetAllBenutzerrollen(): Promise<AxiosResponse> {
    return apiClient.get('/benutzerrollen');
  },

  CreateBenutzerUnternehmen(data: BenutzerUnternehmen): Promise<AxiosResponse> {
    return apiClient.post('/benutzer/unternehmen', data)
  },

  AddBeraterUnternehmen(data: BeraterUnternehmenRequest): Promise<AxiosResponse> {
    return apiClient.post('/benutzer/berater/unternehmen', data)
  },

  RemoveBenutzerUnternehmen(id: number): Promise<AxiosResponse> {
    return apiClient.delete('/benutzer/unternehmen/' + id)
  },
  //#endregion

  //#region Fragenkatalog
  getAllFragenkatalog(): Promise<AxiosResponse> {
    return apiClient.get('/fragenkatalog')
  },

  getFragenkatalogById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/fragenkatalog/${id}`)
  },

  CreateFragenkatalog(data: Fragenkatalog): Promise<AxiosResponse> {
    return apiClient.post('/fragenkatalog', data)
  },

  CreateFragenkatalogRange(data: Array<Fragenkatalog>): Promise<AxiosResponse> {
    return apiClient.post('/fragenkatalog/range', data)
  },

  UpdateFragenkatalog(data: Fragenkatalog): Promise<AxiosResponse> {
    return apiClient.put('/fragenkatalog', data)
  },

  UpdateFragenkatalogRange(data: Array<Fragenkatalog>): Promise<AxiosResponse> {
    return apiClient.put('/fragenkatalog/range', data)
  },

  DeleteFragenkatalog(id: number): Promise<AxiosResponse> {
    return apiClient.delete(`/fragenkatalog/${id}`)
  },
  //#endregion

  //#region Longlist
  GetUmfrageLonglist(umfrageGuid: string): Promise<AxiosResponse> {
    return apiClient.get(`/longlist/umfrage/${umfrageGuid}`)
  },

  GetLonglistById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/longlist/${id}`)
  },

  CreateLonglist(data: LongList): Promise<AxiosResponse> {
    return apiClient.post('/longlist', data)
  },

  CreateLonglistRange(data: Array<LongList>): Promise<AxiosResponse> {
    return apiClient.post('/longlist/range', data)
  },

  UpdateLonglist(data: LongList): Promise<AxiosResponse> {
    return apiClient.put('/longlist', data)
  },

  UpdateLonglistRange(data: Array<LongList>): Promise<AxiosResponse> {
    return apiClient.put('/longlist/range', data)
  },

  DeleteLonglist(id: number): Promise<AxiosResponse> {
    return apiClient.delete(`/longlist/${id}`)
  },
  //#endregion

  //#region LonglistStakeholder
  GetAllLonglistStakeholder(): Promise<AxiosResponse> {
    return apiClient.get(`/longlist/stakeholder`)
  },

  GetLonglistStakeholderById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/longlist/stakeholder/${id}`)
  },

  CreateLonglistStakeholder(data: LongListStakeholder): Promise<AxiosResponse> {
    return apiClient.post('/longlist/stakeholder', data)
  },

  CreateLonglistStakeholderRange(data: Array<LongListStakeholder>): Promise<AxiosResponse> {
    return apiClient.post('/longlist/stakeholder/range', data)
  },

  DeleteLonglistStakeholder(id: number): Promise<AxiosResponse> {
    return apiClient.delete(`/longlist/stakeholder/${id}`)
  },
  //#endregion

  //#region Stakeholder
  GetAllStakeholder(): Promise<AxiosResponse> {
    return apiClient.get('/stakeholder')
  },

  GetStakeholderById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/stakeholder/${id}`)
  },

  CreateStakeholder(data: Stakeholder): Promise<AxiosResponse> {
    return apiClient.post('/stakeholder', data)
  },

  UpdateStakeholder(data: Stakeholder): Promise<AxiosResponse> {
    return apiClient.put('/stakeholder', data)
  },

  DeleteStakeholder(id: number): Promise<AxiosResponse> {
    return apiClient.delete(`/stakeholder/${id}`)
  },
  //#endregion

  //#region Teilnehmer
  getAllTeilnehmer(): Promise<AxiosResponse> {
    return apiClient.get('/teilnehmer')
  },

  getTeilnehmerByGuid(guid: string): Promise<AxiosResponse> {
    return apiClient.get(`/teilnehmer/${guid}`)
  },

  getAllTeilnehmerByUmfrageguid(guid: string): Promise<AxiosResponse> {
    return apiClient.get(`/teilnehmer/umfrage/${guid}`)
  },

  CreateTeilnehmer(data: Teilnehmer): Promise<AxiosResponse> {
    return apiClient.post('/teilnehmer', data)
  },

  UpdateTeilnehmer(data: Teilnehmer): Promise<AxiosResponse> {
    return apiClient.put('/teilnehmer', data)
  },

  DeleteTeilnehmer(guid: string): Promise<AxiosResponse> {
    return apiClient.delete(`/teilnehmer/${guid}/final`)
  },

  SendTeilnehmerEmail(data: TeilnehmerEmail): Promise<AxiosResponse> {
    return apiClient.post('/teilnehmer/email', data)
  },
  //#endregion

  //#region TeilnehmerAntwort
  getAllTeilnehmerAntwort(): Promise<AxiosResponse> {
    return apiClient.get('/teilnehmer/antwort')
  },

  getTeilnehmerAntwortById(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/teilnehmer/antwort/${id}`)
  },

  getAllTeilnehmerAntwortByTeilnehmerguid(guid: string): Promise<AxiosResponse> {
    return apiClient.get(`/teilnehmer/antwort/teilnehmer/${guid}`)
  },

  CreateTeilnehmerAntwort(data: TeilnehmerAntwort): Promise<AxiosResponse> {
    return apiClient.post('/teilnehmer/antwort', data)
  },

  CreateTeilnehmerAntwortRange(data: Array<TeilnehmerAntwort>): Promise<AxiosResponse> {
    return apiClient.post('/teilnehmer/antwort/range', data)
  },

  UpdateTeilnehmerAntwort(data: TeilnehmerAntwort): Promise<AxiosResponse> {
    return apiClient.put('/teilnehmer/antwort', data)
  },

  UpdateTeilnehmerAntwortRange(data: Array<TeilnehmerAntwort>): Promise<AxiosResponse> {
    return apiClient.put('/teilnehmer/antwort/range', data)
  },

  DeleteTeilnehmerAntwort(id: number): Promise<AxiosResponse> {
    return apiClient.delete(`/teilnehmer/antwort/${id}`)
  },
  //#endregion

  //#region Umfrage
  getAllUmfrage(): Promise<AxiosResponse> {
    return apiClient.get('/umfrage')
  },

  getUmfrageByGuid(guid: string): Promise<AxiosResponse> {
    return apiClient.get(`/umfrage/${guid}`)
  },

  getAllUmfrageByUnternehmenguid(guid: string): Promise<AxiosResponse> {
    return apiClient.get(`/umfrage/unternehmen/${guid}`)
  },

  GetActiveUmfragenByUnternehmenguid(guid: string): Promise<AxiosResponse> {
    return apiClient.get(`/umfrage/unternehmen/${guid}/aktiv`)
  },

  CreateUmfrage(data: Umfrage): Promise<AxiosResponse> {
    return apiClient.post('/umfrage', data)
  },

  UpdateUmfrage(data: Umfrage): Promise<AxiosResponse> {
    return apiClient.put('/umfrage', data)
  },

  DeleteUmfrage(guid: string): Promise<AxiosResponse> {
    return apiClient.delete(`/umfrage/${guid}`)
  },
  //#endregion

  //#region Unternehmen
  getAllUnternehmen(): Promise<AxiosResponse> {
    return apiClient.get('/unternehmen')
  },

  getUnternehmenByGuid(guid: string): Promise<AxiosResponse> {
    return apiClient.get(`/unternehmen/${guid}`)
  },

  getUnternehmenByFirmennummer(firmennummer: string): Promise<AxiosResponse> {
    return apiClient.get(`/unternehmen/firmennummer/${firmennummer}`)
  },

  CreateUnternehmen(data: Unternehmen): Promise<AxiosResponse> {
    return apiClient.post('/unternehmen', data)
  },

  UpdateUnternehmen(data: Unternehmen): Promise<AxiosResponse> {
    return apiClient.put('/unternehmen', data)
  },

  DeleteUnternehmen(guid: string): Promise<AxiosResponse> {
    return apiClient.delete(`/unternehmen/${guid}`)
  },
  //#endregion

  //#region Unternehmen
  GetAllUnternehmensthema(unternhemenGuid: string = ""): Promise<AxiosResponse> {
    return apiClient.get(`/unternehmensthemen?unternehmenGuid=${unternhemenGuid}`)
  },

  CreateUnternehmensthema(data: Unternehmensthema): Promise<AxiosResponse> {
    return apiClient.post('/unternehmensthema', data)
  },

  UpdateUnternehmensthema(data: Unternehmensthema): Promise<AxiosResponse> {
    return apiClient.put('/unternehmensthema', data)
  },

  DeleteUnternehmensthema(id: number): Promise<AxiosResponse> {
    return apiClient.delete(`/unternehmensthema/${id}`)
  },
  //#endregion
}