<template>
  <Sidebar  v-if="!isPublicPage && user != null && user.benutzerrolleId != 3" />
  
  <div class="d-flex flex-column w-100">
    <Navbar />
    <!-- <AutoLogout v-if="currentRouteName != 'Login' && loggedIn" /> -->
    <LoadingSpinner v-if="authLoading" text="... Sie werden abgemeldet ..." />
    
    <div id="main-content" class="p-3">
      <router-view />
    </div>

    <Footer />
  </div>
</template>

<script lang="ts">
import Navbar from "@/components/Navbar.vue";
import Footer from "./components/Footer.vue";
import Sidebar from "./components/Sidebar.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import { defineComponent, computed } from "vue";
import { useStore } from "@/store";
import { useRoute } from 'vue-router';

export default defineComponent({
  name: "App",
  
  components: {
    Navbar,
    Footer,
    Sidebar,
    LoadingSpinner
  },

  setup () {
    const store = useStore()
    const route = useRoute();

    const authLoading = computed(() => store.getters.status.authLogoutLoading)

    // const publicPages = ["Home", "Umfrage", "Login", "Logout", "Register"]
    const publicPages = store.getters.publicPages;
    const isPublicPage = computed(() => route.name != null && route.name != "" && publicPages.includes(route.name.toString()))
    const user = computed(() => store.getters.authUser?.benutzer);

    return {
      authLoading,
      isPublicPage,
      
      user
    }
  }

});
</script>

<style lang="scss">
@import "./assets/sass/main.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  display: flex;
}

#main-content {
  height: 100%;
  background: rgb(242, 243, 248);
}

/* Custom scrollbar styling */
/* Webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
</style>
