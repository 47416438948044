<template>
  <div v-if="stakeholderid != '' && stakeholder == null" class="alert alert-danger">
    Der Stakeholder konnte nicht gefunden werden.

    <button type="button" class="btn btn-secondary me-3" @click="cancel()">Zurück</button>
  </div>

  <div v-else>
    <h1 v-if="editStakeholder.id == 0">Neuer Stakeholder</h1>
    <h1 v-else>{{ stakeholder.name }} bearbeiten</h1>
  </div>

  <hr class="my-4">

  <LoadingSpinner v-if="loading" text="... Daten werden geladen ..." />

  <div v-else class="mt-3">
    <form @submit.prevent="saveStakeholder()">
      <div class="mb-3">
        <label for="frage-frageDE" class="form-label">Name</label>
        <input type="text" class="form-control" id="frage-frageDE" v-model="editStakeholder.name">
      </div>

      <div class="mb-3">
        <label for="frage-beschreibungDE" class="form-label">Beschreibung</label>
        <textarea type="text" class="form-control" id="frage-beschreibungDE" rows="3" v-model="editStakeholder.beschreibung"></textarea>
      </div>

      <div v-if="errorMsg != ''" class="alert alert-danger my-3">
        Es ist ein Fehler aufgetreten. Bite probieren Sie es später erneut.
      </div>

      <div class="d-flex justify-content-end">
        <button type="button" class="btn btn-secondary me-3" @click="cancel()">Abbrechen</button>
        <button type="submit" class="btn btn-primary">Speichern</button>
      </div>
    </form>

    <hr class="my-4">

    <button type="button" class="btn btn-danger" @click="deleteStakeholder()"><font-awesome-icon icon="trash" /> Stakeholder löschen</button>
  </div>

</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { Stakeholder } from '@/models/StakeholderModels';

import { ActionTypes as StakeholderActionTypes } from '@/store/modules/Stakeholder/actions';

import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default defineComponent({
  name: "EditStakeholderView",

  components: {
    LoadingSpinner
  },

  props: {
    stakeholderid: {
      type: String as PropType<string>,
      default: ""
    }
  },

  setup (props) {
    const store = useStore()
    const router = useRouter()

    const loading = computed(() => store.getters.status.updateStakeholderLoading || store.getters.status.createStakeholderLoading || store.getters.status.deleteStakeholderLoading);
    const errorMsg = computed(() => store.getters.status.stakeholderErrorMsg);

    const stakeholder = computed(() => store.getters.stakeholder.find((f: Stakeholder) => f.id == Number.parseInt(props.stakeholderid)));
    console.log(stakeholder.value);
    const editStakeholder = ref<Stakeholder>(stakeholder.value != null ? JSON.parse(JSON.stringify(stakeholder.value)) as Stakeholder : new Stakeholder());

    function saveStakeholder () {
      if (editStakeholder.value.id == 0) {
        store.dispatch(StakeholderActionTypes.CreateStakeholder, editStakeholder.value)
          .then(() => {
            if (errorMsg.value == '') {
              cancel()
            }
          });
      }
      else {
        store.dispatch(StakeholderActionTypes.UpdateStakeholder, editStakeholder.value)
          .then(() => {
            if (errorMsg.value == '') {
              cancel()
            }
          });
      }
    }

    function deleteStakeholder () {
      store.dispatch(StakeholderActionTypes.DeleteStakeholder, editStakeholder.value.id)
        .then(() => {
          if (errorMsg.value == '') {
            cancel()
          }
        });
    }

    function cancel() {
      router.back();
    }

    return {
      loading,
      errorMsg,

      stakeholder,
      editStakeholder,

      saveStakeholder,
      deleteStakeholder,
      cancel
    }
  }
})
</script>