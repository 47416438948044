import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_3 = {
  key: 1,
  class: "mt-3 p-3 bg-white shadow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-outline-secondary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
      }, [
        _createVNode(_component_font_awesome_icon, { icon: "chevron-left" }),
        _createTextVNode(" Zurück")
      ]),
      (_ctx.stakeholder != null && _ctx.user.benutzerrolleId == 1)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: {name: 'EditStakeholder', query: {stakeholderid: _ctx.stakeholderid}},
            class: "btn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "pen" })
            ]),
            _: 1
          }, 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.stakeholder == null)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " Der Stakeholder konnte nicht gefunden werden. "))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.stakeholder.name), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.stakeholder.beschreibung), 1)
        ]))
  ], 64))
}