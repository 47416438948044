import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row py-2 mb-2 mx-1 rounded" }
const _hoisted_2 = { class: "col-4 bg-white shadow-sm p-3" }
const _hoisted_3 = { class: "col border-top border-end border-bottom pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StakeholderRow = _resolveComponent("StakeholderRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.titel), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.longlist.stakeholder, (stakeholder) => {
        return (_openBlock(), _createBlock(_component_StakeholderRow, {
          key: stakeholder.id,
          stakeholder: _ctx.getStakeholderById(stakeholder.stakeholderid),
          class: "mb-2"
        }, null, 8, ["stakeholder"]))
      }), 128))
    ])
  ]))
}