import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row py-2 mb-1 mx-1 bg-white shadow-sm rounded" }
const _hoisted_2 = { class: "col-5" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "col-3 my-auto border-end" }
const _hoisted_6 = { class: "col-2 my-auto" }
const _hoisted_7 = { class: "col-3 my-auto border-end" }
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col my-auto" }
const _hoisted_11 = { class: "col my-auto" }
const _hoisted_12 = { class: "col my-auto" }
const _hoisted_13 = { class: "col my-auto border-end" }
const _hoisted_14 = { class: "col-3" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col my-auto" }
const _hoisted_17 = { class: "col my-auto" }
const _hoisted_18 = { class: "col my-auto" }
const _hoisted_19 = { class: "col my-auto border-end" }
const _hoisted_20 = { class: "col-1 my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "col my-auto cut-text",
          "data-bs-toggle": "tooltip",
          "data-bs-placement": "top",
          title: _ctx.frage.frageDE
        }, _toDisplayString(_ctx.frage.nr) + ". " + _toDisplayString(_ctx.frage.frageDE), 9, _hoisted_4),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.frage.quelle), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.countRelevant), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.countNichtRelevant), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.countInsideOut), 1),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.countOutsideIn), 1),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.countBothWays), 1),
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.countRichtungDontKnow), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.countShortTerm), 1),
        _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.countMediumTerm), 1),
        _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.countLongTerm), 1),
        _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.countTermDontKnow), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.anzahlAntworten), 1)
  ]))
}